<template>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度やり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisiblePreRegistError"
    message="仮登録の有効期限が切れました。<br>新規ユーザー申請からもう一度やり直してください"
    title="有効期限切れ"
    btn1Text="OK"
    messageType="2"
    @close="closePreRegistError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleMessage1"
    message="サインインIDとパスワードは必ず入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialog1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleMessage2"
    message="サインインIDまたはパスワードに誤りがあります。"
    title="サインインエラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialog2"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleServerError"
    :message="ServerErrorMessage"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogServerError"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="サインイン中です。"
    title="サインイン中"
  ></ProgressBox>
  <MenuPanel PageName="Reps　サインイン" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <form autocomplete="off">
        <table>
          <tr>
            <td>サインインID:</td>
            <td><LabelBox id="userid" TypeName="text" v-model="userid" name="userid" maxlength="20"></LabelBox></td>
          </tr>
          <tr>
            <td>パスワード:</td>
            <td><LabelBox id="password" TypeName="password" name="password" oncontextmenu="return false" maxlength="20"></LabelBox></td>
          </tr>
        </table>
      </form>
      <div>
        <ButtonBox name="LoginButton" ButtonName="サインイン" @click="login()"></ButtonBox>
      </div>
      <div>
        <p class="passwordReset" @click="resetPassword()">パスワードを忘れた方はこちら</p>
      </div>
    </div>
    <div class="footer">
      <p>©2023 RICOH JAPAN Corp. All Rights Reserved. {{ update_timestamp }} Update.</p>
    </div>
  </div>
</template>

<script>
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import CommonModule from '../modules/commonCognito.js'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'
import commonCognito from '../modules/commonCognito.js'

export default {
  name: 'UserLogin',
  /*
  async beforeRouteLeave(to, from, next) {
    try {
      await sessionStorage.setItem('AuthBefore', '/')
      next()
    } catch (e) {
      Common.logPrint(str(e))
      return false
    }
  },
  */
  components: {
    ModalBox,
    LabelBox,
    ButtonBox,
    ProgressBox,
    MenuPanel
  },
  data () {
    sessionStorage.clear()
    return {
      userid: '',
      isVisibleMessage1: false,
      isVisibleMessage2: false,
      isVisibleProgress1: false,
      isVisiblePreRegistError: false,
      isVisibleServerError: false,
      isVisibleTimeout: false,
      ServerErrorMessage: '',
      update_timestamp: Common.UPDATE_TIMESTAMP 
    }
  },
  mounted() {
    sessionStorage.clear()
    Common.ModuleStatus()
  },
  computed: {
  },
  methods: {
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
    },
    closeDialogServerError (e) {
      this.isVisibleServerError = false
    },
    closePreRegistError (e) {
      this.isVisiblePreRegistError = false
    },
    closeDialog1 (e) {
      this.isVisibleMessage1 = false
    },
    closeDialog2 (e) {
      this.isVisibleMessage2 = false
      document.getElementById('password').value=''
      document.getElementById('userid').value=''
    },
    async login () {
      // 入力サインインID、パスワードを取得
      const password = document.getElementById('password').value
      const userID = document.getElementById('userid').value

      if (userID === '' || password === '') {
        // alert('サインインIDとパスワードのいずれかが入力されていません。')
        this.isVisibleMessage1 = true
        return false
      }

      this.isVisibleProgress1 = true
      let resultUserPass
      // CognitoによるサインインID/パスワードの確認を実施した結果からエラーの場合は処理を中断させる
      if (!Common.VulnerabilityAssessment) {
        resultUserPass = await this.checkUserIDPassword(userID, password)
      } else {
        resultUserPass = 200
      }

      switch (resultUserPass) {
        // 本登録ユーザー認証OK→認証コード入力へ
        case 200:
          this.isVisibleProgress1 = false
          sessionStorage.setItem('userid', userID)
          sessionStorage.setItem('AuthBefore', '/')
          this.$router.replace({ name: 'Auth' })
          break

        // 仮登録ユーザー認証OK→新規ユーザー登録へ
        case 201:
          // ユーザーの仮登録状況を確認しに行く
          const [statusCode, body] = await this.getRegistableFromRDS(userID)
          if (statusCode === 200) {
            Common.logPrint(body)
            const response = body
            const registable = response.Registable
            sessionStorage.setItem('userid', userID)
            
            // 仮登録の場合の遷移について
            if (!registable) {
              this.isVisibleProgress1 = false
              // 仮登録の有効期限が切れているため、NGとし、処理を行わない。
              // 申請からやり直しとなる。(仮登録ユーザーで、有効期限が切れている人は、ハウスキーピングで削除される予定)
              this.isVisiblePreRegistError = true
              sessionStorage.clear()
              return
            }

            // 新規ユーザー情報登録画面へ遷移
            this.$router.replace({ name: 'AppendNewUser2' })
            return
          } else {
            this.isVisibleProgress1 = false
            const errCode = await Common.getErrorCodeFromResponceBody(body)
            Common.logPrint(errCode)
            switch(errCode) {
              case 501:
                this.isVisibleMessage2 = true
                break
              
              // Time Out
              case 510:
                this.isVisibleTimeout = true
                break
                    
              default:
                this.ServerErrorMessage = 'サインインに失敗しました。<br>' + 
                                          'しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>' + 
                                          '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' + 
                                          'Message:' + body
                this.isVisibleServerError = true
                break
            }
          }
        
        // エラー処理は上部関数で行っているため、プログレスを切るだけでOK
        default:
          this.isVisibleProgress1 = false
      }
    },    
    resetPassword () {
      sessionStorage.setItem('passwordReset','0')
      this.$router.replace({ name: 'PasswordReset' })
    },
    async checkUserIDPassword (iuserID, ipassword) {
      
      const url = URLS.userLogin_CheckUserIDAndPassword[Common.RELEASE]
      Common.logPrint(url)
      const requestBody = {
        userID: iuserID,
        password: ipassword
      }

      const response = await Common.POSTRequestWithoutAuth(url, requestBody)
      const [status, body] = await Common.POSTResponse(response, this)
      // 
      //const status=201
      // StatusCode:200 →本登録確認OK
      // StatusCode:201 →仮登録確認OK
      if (status === 200 || status === 201) {
        sessionStorage.setItem('mfaSession', body);
        sessionStorage.setItem('mfaUserId', iuserID);
        return status
      
      // サインインID or パスワードが違います。
      } else if (status === 501 || status === 500) {
        this.isVisibleProgress1 = false
        Common.logPrint("checkUserIDPassword:" + status)
        this.isVisibleMessage2 = true
        return status
      
      // それ以外のエラー
      } else {
        this.isVisibleProgress1 = false
        const errCode = await Common.getErrorCodeFromResponceBody(body)
        switch(errCode) {
          // Time Out
          case 510:
            Common.logPrint("タイムアウト検知")
            this.isVisibleTimeout = true
            break
                
          default:
            this.ServerErrorMessage = 'サインインに失敗しました。<br>' + 
                                      'しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>' + 
                                      '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' + 
                                      'Message:' + body
            this.isVisibleServerError = true
            break
        }
        return status
      }
    },
    async getRegistableFromRDS (iuserID) {
      //const url = URLS.userLogin_getUserInformation[Common.RELEASE] // ← このLambdaはIdToken無しで呼ばれないとダメ
      const url = URLS.userLogin_getUserRegistable[Common.RELEASE]
      const requestBody = {
        userid: iuserID
      }

      const response = await Common.POSTRequestWithoutAuth(url, requestBody)
      const ret = await Common.POSTResponse(response, this)
      return ret
    },
    created() {
    },
  }
}
</script>

<style scoped>
  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  td {
    margin: 10px;
    text-align: left;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    padding-top: 50px;
  }

  .home {
    margin: auto;
    padding-top: 10px;
    line-height: 2.5;
    width: 350px;
    color: #fff;
    background-color: rgb(255, 255, 255);
  }

  .passwordReset {
    font-size: 12px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }

  .footer {
    font-size: 11px;
    text-align: right;
    background-color: white;
    color: #aaa;
    margin-right: 0px;
    margin-left: auto;
    width: 640px;
  }
</style>
