<template>
  <div class="CP009_passwordPolicy">
    <div :class="style1"><input id="CP009_checkList1" class="CP009_chkStyle" type="checkbox" name="check_Data" disabled>パスワード文字数が10文字以上</div>
    <div :class="style2"><input id="CP009_checkList2" class="CP009_chkStyle" type="checkbox" name="check_Data" disabled>少なくとも1文字以上の数字</div>
    <div :class="style3"><input id="CP009_checkList3" class="CP009_chkStyle" type="checkbox" name="check_Data" disabled>少なくとも1文字以上の特殊文字( ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , &gt; &lt; ' : ; | _ ~ ` + = )</div>
    <div :class="style4"><input id="CP009_checkList4" class="CP009_chkStyle" type="checkbox" name="check_Data" disabled>少なくとも1文字以上の大文字</div>
    <div :class="style5"><input id="CP009_checkList5" class="CP009_chkStyle" type="checkbox" name="check_Data" disabled>少なくとも1文字以上の小文字</div>
  </div>
</template>

<script>
import Common from '../modules/common.js'
const componentID = 'CP009'
const specialCharacters = "^$*.[]{}()?-\"!@#%&/\\,><\':;|_~\`+="

export default {
  name: 'PasswordCheck',
  props: {
    
  },
  data () {
    return {
      style1: "CP009_uncompleted",
      style2: "CP009_uncompleted",
      style3: "CP009_uncompleted",
      style4: "CP009_uncompleted",
      style5: "CP009_uncompleted"
    }
  },
  mounted() {
      
  },
  methods: {
    fullCompleted () {
      const result1 = document.getElementById("CP009_checkList1").checked
      const result2 = document.getElementById("CP009_checkList2").checked
      const result3 = document.getElementById("CP009_checkList3").checked
      const result4 = document.getElementById("CP009_checkList4").checked
      const result5 = document.getElementById("CP009_checkList5").checked
      Common.logPrint(result1)
      Common.logPrint(result2)
      Common.logPrint(result3)
      Common.logPrint(result4)
      Common.logPrint(result5)
      return result1 && result2 && result3 && result4 && result5
    },
    checkPasswordPolicy (password) {
      // Length Check
      Common.logPrint(password)
      if (password.length >= 10) {
        document.getElementById("CP009_checkList1").checked = true
        this.style1 = "CP009_completed"
      } else {
        document.getElementById("CP009_checkList1").checked = false
        this.style1 = "CP009_uncompleted"
      }

      // 数字が含まれているかチェック
      if (/\d/.test(password)) {
        document.getElementById("CP009_checkList2").checked = true
        this.style2 = "CP009_completed"
      } else {
        document.getElementById("CP009_checkList2").checked = false
        this.style2 = "CP009_uncompleted"
      }   
      
      // 特殊文字が含まれているかチェック
      if (Array.from(password).some(char => specialCharacters.includes(char))) {
        document.getElementById("CP009_checkList3").checked = true
        this.style3 = "CP009_completed"
      } else {
        document.getElementById("CP009_checkList3").checked = false
        this.style3 = "CP009_uncompleted"
      }

      // 小文字が含まれているかチェック
      if (/[A-Z]/.test(password)) {
        document.getElementById("CP009_checkList4").checked = true
        this.style4 = "CP009_completed"
      } else {
        document.getElementById("CP009_checkList4").checked = false
        this.style4 = "CP009_uncompleted"
      }

      // 大文字が含まれているかチェック
      if (/[a-z]/.test(password)) {
        document.getElementById("CP009_checkList5").checked = true
        this.style5 = "CP009_completed"
      } else {
        document.getElementById("CP009_checkList5").checked = false
        this.style5 = "CP009_uncompleted"
      }     
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .CP009_completed {
    text-decoration: line-through;
  }

  .CP009_uncompleted {
    text-decoration: none;
  }
  
  .CP009_passwordPolicy {
    line-height: 1.0;
    font-size: 11px;
    color:#000;
    text-align: left;
  }

  .CP009_chkStyle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 20px;
    height: 10px;
  }

  .CP009_chkStyle:checked:disabled::after {
    font-size: 16px;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -2px;
    content: '\2713';
    color: rgb(49, 150, 49);
    text-align: center;
    vertical-align: middle;
  }

  .CP009_chkStyle:disabled::after {
    font-size: 16px;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -2px;
    content: '\2718';
    color: rgb(255, 0, 0);
    text-align: center;
    vertical-align: middle;
  }
</style>
