<template>
  <ModalBox
    :isVisible="isVisibleMessage1"
    message="サインインIDとパスワードは必ず入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialog1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleMessage2"
    message="サインインIDまたはパスワードに誤りがあります。"
    title="サインインエラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialog2"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleUnmatched"
    message="サインインIDが異なっています。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogUnmatched"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度やり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="ユーザー認証の実行中です。"
    title="認証処理中"
  ></ProgressBox>
  <MenuPanel class="DeletePanel" PageName="ユーザー削除実行" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <table>
        <tr>
          <td>サインインID:</td>
          <td><LabelBox id="userid" TypeName="username" v-model="userid" name="userid" maxlength="20"></LabelBox></td>
        </tr>
        <tr>
          <td>パスワード:</td>
          <td><LabelBox id="password" TypeName="password" name="password" oncontextmenu="return false" maxlength="20"></LabelBox></td>
        </tr>
      </table>
      <div>
        <ButtonBox name="ReturnButton" ButtonName="サインイン画面に戻る" @click="ReturnLogin()"></ButtonBox>
        <ButtonBox name="DeleteButton" ButtonName="削除実行" class="align-right" @click="execDelete()"></ButtonBox>
      </div>
    </div>
  </div>
</template>

<script>
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import CommonModule from '../modules/commonCognito.js'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'
export default {
  name: 'UserLogin',
  components: {
    LabelBox,
    ButtonBox,
    ModalBox,
    MenuPanel,
    ProgressBox
  },
  data () {
    return {
      userid: '',
      isVisibleMessage1: false,
      isVisibleMessage2: false,
      isVisibleProgress1: false,
      isVisibleTimeout: false,
      ServerErrorMessage: '',
      isVisibleServerError: false,
      isVisibleUnmatched: false
    }
  },
  computed: {
  },
  methods: {
    closeDialogUnmatched () {
      this.isVisibleUnmatched = false
    },
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
    },
    closeDialogServerError (e) {
      this.isVisibleServerError = false
    },
    closeDialog1 (e) {
      this.isVisibleMessage1 = false
    },
    closeDialog2 (e) {
      this.isVisibleMessage2 = false
      document.getElementById('password').value=''
      document.getElementById('userid').value=''
    },
    async execDelete () {
      // UserID/Passwordの入力は必須
      const password = document.getElementById('password').value
      const userID = document.getElementById('userid').value
      const targetUserID = sessionStorage.getItem('DeleteUserID')

      // 入力漏れ
      if (userID === '' || password === '') {
        // alert('サインインIDとパスワードのいずれかが入力されていません。')
        this.isVisibleMessage1 = true
        return
      }

      // 削除対象サインインIDが異なっている(他のサインインID削除を撲滅する)
      if (userID !== targetUserID) {
        this.isVisibleUnmatched = true
        return
      }

      this.isVisibleProgress1 = true
      // const userID = 'TestUser01'
      const result = await this.checkUserIDPassword(userID, password)
      if (!result) {
        this.isVisibleProgress1 = false
        return
      }

      // ログインIDをストア
      sessionStorage.setItem('DeleteUserID', userID)
      
      // 次の画面へ遷移
      this.$router.replace('AuthDelete')
    },
    ReturnLogin () {
      this.$router.replace('/')
    },
    async checkUserIDPassword (iuserID, ipassword) {
      const url = URLS.userLogin_CheckUserIDAndPassword[Common.RELEASE]
      const requestBody = {
        userID: iuserID,
        password: ipassword
      }

      const response = await Common.POSTRequestWithoutAuth(url, requestBody)
      const [status, body] = await Common.POSTResponse(response, this)

      // サインイン後ステータスを確認し、処理を行う。
      if (status === 200) {
        sessionStorage.setItem('mfaSession', body);
        sessionStorage.setItem('mfaUserId', iuserID);
        return true
      // サインインID or パスワードが違います。
      } else if (status == 501 || status === 500) {
        this.isVisibleMessage2 = true
        return false
      } else {
        this.isVisibleProgress1 = false
        const errCode = await Common.getErrorCodeFromResponceBody(body)
        switch(errCode) {
          // Time Out
          case 510:
            Common.logPrint("タイムアウト検知")
            this.isVisibleTimeout = true
            break
                
          default:
            this.ServerErrorMessage = 'サインインに失敗しました。<br>' +
                                      'しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>' +
                                      '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' + 
                                      'Message:' + body
            this.isVisibleServerError = true
            break
        }
        return false
      }
    },
    closeDialog1 (e) {
      this.isVisibleMessage1 = false
    },
    closeDialog2 (e) {
      this.isVisibleMessage2 = false
    }
  }
}
</script>

<style scoped>
  .DeletePanel {
    background-color: rgb(238, 127, 127);
  }

  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  td {
    margin: 10px;
    text-align: left;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    padding-top: 50px;
  }
  .home {
    margin: auto;
    padding-top: 10px;
    line-height: 2.5;
    width: 350px;
    color: #fff;
    background-color: rgb(255, 255, 255);
  }
</style>
