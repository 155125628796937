<!---
  ラジオボタン付きテーブル表示
  ページャー付き
  ソーター付き
   同じ列をもう一度クリックすると、反転ソート
--->
<template>
  <div class="CP008_TableDataView">
    <table align="left" id="CP008_tableDatas" class="CP008_tableData">
      <caption>
        <div class="CP008_pager">
          <table v-if="Datas.length > 0" class="CP008_pagerTable">
            <tr>
              <td class="CP008_spaceColumnLeft">
                表示件数:<SelectBox @change="selectShowCount" name="showCount" :initIndex=4 :Datas="showCountSelector"></SelectBox>件
              </td>
              <td class="CP008_spaceColumnCenter">
                <table class="CP008_pageSelector">
                  <td class="CP008_firstPage">
                    <button @click="firstPage" v-if="totalPage > 1 && currentPage !== 1">|&lt;</button>
                  </td>
                  <td class="CP008_previousPage">
                    <button @click="previousPage" v-if="totalPage > 1 && currentPage !== 1">&lt;</button>
                  </td>
                  <td class="CP008_pageShow">
                    <label>総件数{{ Datas.length }}件中 {{ (currentPage -1) * perPage + 1}} - {{ (currentPage -1) * perPage + showItems.length }}件目 を表示中 ({{ currentPage }} / {{ showPageInfo }})</label>
                  </td>
                  <td class="CP008_nextPage">
                    <button @click="nextPage" v-if="totalPage > 1 && currentPage !== totalPage">&gt;</button>
                  </td>
                  <td class="CP008_lastPage">
                    <button @click="lastPage" v-if="totalPage > 1 && currentPage !== totalPage">&gt;|</button>
                  </td>
                </table>
              </td>
              <td class="CP008_spaceColumnRight"></td>
            </tr>
          </table>
        </div>
      </caption>
      <thead class="CP008_showHeader">
        <tr>
          <th width="30px">選択</th>
          <th class="CP008_headers" v-for="item, index in showHeaders" :class="item.align" :width="item.width" v-bind:key="index" @click="sortDatasCompute(index)">{{ item.data }}<p class="CP008_indicator"></p></th>
        </tr>
      </thead>
      <tbody class="CP008_showData">
        <tr v-for="data in showItems" v-bind:key="data.value" @click="checkData($event)">
          <td width="30px" class="CP008_check_data"><input type="radio" name="CP008_check_data" v-model="data.checked" @change="checkRadio(data)"></td>
          <td v-for="coldata in data" :class="coldata.align" v-bind:key="coldata.index" :width="coldata.width" v-html="coldata.bContent + coldata.data + coldata.aContent"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SelectBox from './SelectBox.vue'
import Common from '../modules/common.js'

const componentID = 'CP008'

export default {
  name: 'DataShowTable',
  components: {
    SelectBox
  },
  data () {
    return {
      currentPage: 1,
      totalPage: 1,
      showCountSelector: ['5', '10', '25', '50', '100'],
      perPage: 100,
      checkedRow: -1,
      sortKey: -1,
      nowSort: -1,
      desc: false,
      datatype: [],
      dataalign: [],
      sortkeycol: [],
      beforecontent: [],
      aftercontent: []
    }
  },
  props: {
    Headers: Array,
    Datas: Array,
    name: String,
    filterKey: {
      type: String,
      default: ''
    },
    colSize: Array,
    dataType: {
      type: Array,
      default: []
    },
    dataAlign: {
      type: Array,
      default: []
    },
    beforeContent: {
      type: Array,
      default: []
    },
    afterContent: {
      type: Array,
      default: []
    }
  },
  watch: {
    Datas: {
      handler(newDatas) {
        this.currentPage = 1
      }
    }
  },
  updated() {
    this.clearSelect()
  },
  created () {
    // propsの値は受け取りが終わり、dataの情報も持っている時に実行
    this.totalPage = Math.ceil(this.Datas.length / this.perPage)
  },
  mounted () {
    const colcount = this.Headers.length
    if (this.dataType.length === 0) {
      for (let i = 0; i < colcount; i++){
        this.datatype.push('String')
      }
    } else {
      this.datatype = this.dataType
    }
    if (this.dataAlign.length === 0) {
      for (let i = 0; i < colcount; i++){
        this.dataalign.push('left')
      }
    } else {
      this.dataalign = this.dataAlign
    }
    if (this.beforeContent.length === 0) {
      for (let i = 0; i < colcount; i++){
        this.beforecontent.push('')
      }
    } else {
      this.beforecontent = this.beforeContent
    }
    if (this.afterContent.length === 0) {
      for (let i = 0; i < colcount; i++){
        this.aftercontent.push('')
      }
    } else {
      this.aftercontent = this.afterContent
    }
    /*
    if (this.dataFormat.length === 0) {
      for (let i = 0; i < colcount; i++){
        this.dataformat.push('')
      }
    } else {
      this.dataformat = this.dataFormat      
    }
    */
  },
  methods: {
    selectShowCount: function (e) {
      this.perPage = this.showCountSelector[e.target.value]
      this.totalPage = Math.ceil(this.Datas.length / this.perPage)
      this.currentPage = 1
      this.clearSelect()
    },
    checkRadio: function (data) {
      // 選択行のみ背景色を変更する
      const table = document.getElementById('CP008_tableDatas')
      const rows = table.querySelectorAll('tbody tr')
      let checkrow = 0

      // 背景色を塗り直す
      rows.forEach((row) => {
        if (row.querySelector('input[type="radio"]').checked) {
          //Common.logPrint('ターゲット補足')
          row.style.backgroundColor = '#90d4ff'
          this.checkedRow = checkrow + (this.currentPage - 1) * this.perPage
        } else {
          row.style.backgroundColor = '#ffffff'
        }
        checkrow++
      })
    },
    checkData: function (e) {
      // td要素以外で編集入れないようする。※するとエラーになった
      if (e.target.localName !== 'td') return

      // 行をクリックした場合、ラジオボタンにチェックを入れる対応
      e.target.parentNode.children[0].children[0].checked = true

      // 選択行のみ背景色を変更する
      const table = document.getElementById('CP008_tableDatas')
      const rows = table.querySelectorAll('tbody tr')
      let checkrow = 0

      // 背景色を塗り直す
      rows.forEach((row) => {
        if (row.querySelector('input[type="radio"]').checked) {
          //Common.logPrint('ターゲット補足')
          row.style.backgroundColor = '#90d4ff'
          this.checkedRow = checkrow + (this.currentPage - 1) * this.perPage
        } else {
          row.style.backgroundColor = '#ffffff'
        }
        checkrow++
      })
    },
    sortDatasCompute: function (index) {
      this.sortKey = index
      if (this.sortKey === this.nowSort) {
        this.desc = !this.desc
      } else {
        this.desc = false
      }
    },
    clearSelect: function () {
      // チェックを解除
      for (const element of document.getElementsByName('CP008_check_data')) {
        element.checked = false
      }
      /*
      // チェックを解除
      this.showItems.forEach((data) => {
        data.checked = true
      })
      */

      // 背景色を塗り直す
      const table = document.getElementById('CP008_tableDatas')
      const rows = table.querySelectorAll('tbody tr')
      Common.logPrint(rows)
      rows.forEach((row) => {
        row.style.backgroundColor = '#ffffff'
      })
    },
    getSelectIndex: function () {
      let index = -1
      
      document.getElementsByName('CP008_check_data').forEach(
        function (row, selindex) {
          if (row.checked) {
            index = selindex
          }
        }
      )
      //Common.logPrint('呼ばれたぞ：' + index)
      return index
    },
    getSelectRow: function (index) {
      const row = document.querySelector('#CP008_tableDatas tr:nth-child(' + index + ')')
      return row
    },
    getCellData: function (col, row) {
      const table = document.getElementById('CP008_tableDatas')
      const cell = table.rows[row+1].cells[col]
      const text = cell.textContent
      return text
    },
    nextPage: function () {
      this.currentPage++
      //this.clearSelect()
    },
    previousPage: function () {
      this.currentPage--
      //this.clearSelect()
    },
    lastPage: function () {
      this.currentPage = this.totalPage
      //this.clearSelect()
    },
    firstPage: function () {
      this.currentPage = 1
      //this.clearSelect()
    },
    sortData (datas) {
      // sort処理
      if (this.sortKey !== -1) {
        const skey = this.sortKey
        const self = this
        datas.sort(function (a, b) {
          // 型によってソート処理を変える
          // Common.logPrint(skey)
          switch (self.datatype[self.sortkeycol[skey]]) {
            case 'String':
              const strA = a[skey].data
              const strB = b[skey].data
              // Common.logPrint(strA)
              return strA.localeCompare(strB)

            case 'Number':
              const numA = parseInt(a[skey].data.replace(/,/g, ''))
              const numB = parseInt(b[skey].data.replace(/,/g, ''))
              return numA - numB
          }
        })
        if (this.desc) {
          datas.reverse()
        }
        Common.logPrint(this.sortKey)
        if (this.nowSort !== -1) {
          document.getElementById('CP008_tableDatas').children[1].children[0].children[this.nowSort + 1].children[0].textContent = ''
        }
        Common.logPrint(document.getElementById('CP008_tableDatas').children[1].children[0])
        if (this.desc) {
          document.getElementById('CP008_tableDatas').children[1].children[0].children[this.sortKey + 1].children[0].textContent = '▲'
        } else {
          document.getElementById('CP008_tableDatas').children[1].children[0].children[this.sortKey + 1].children[0].textContent = '▼'
        }
        this.nowSort = skey
      }
      return datas
    }
  },
  computed: {
    showPageInfo: function () {
      return Math.ceil(this.Datas.length / this.perPage)
    },
    showHeaders: function () {
      const retArray = []
      this.sortkeycol=[]
      const self = this
      this.Headers.forEach((element, index) => {
        if (!element.includes('@@@') && (self.colSize[index] !== 'Hidden')) {
          self.sortkeycol.push(index)
          retArray.push(
            {
              data: element + '',
              width: self.colSize[index],
              align: self.dataalign[index]
            }
          )
        }
      })
      return retArray
    },
    showItems: function () {
      const retArray = []
      const self = this
      this.totalPage = Math.ceil(this.Datas.length / this.perPage)
      this.Datas.forEach(element => {
        const rowData = []
        element.forEach((coldata, index) => {
          if (self.colSize[index] !== 'Hidden') {
            if (!self.Headers[index].includes('@@@')) {
              let chgdata = ''
              switch (self.datatype[index]) {
                case 'String':
                  chgdata = coldata
                  break
                case 'Number':
                  chgdata = parseInt(coldata).toLocaleString('ja-JP')
                  break
              }
              //if (self.beforecontent[index] != "") Common.logPrint(self.beforecontent[index])
              rowData.push(
                {
                  data: chgdata,
                  bContent: self.beforecontent[index],
                  aContent: self.aftercontent[index],
                  width: self.colSize[index],
                  align: self.dataalign[index]
                }
              )
            } else {
              const row = rowData.pop()
              rowData.push(
                {
                  data: '<a href="' + coldata + '">' + row.bContent + row.data + row.aContent + '</a>',
                  width: row.width
                }
              )
            }
          }
        })
        retArray.push(rowData)
      })
      const returnArray = this.sortData(retArray)
      // Pagerの制御
      const current = this.currentPage * this.perPage
      const start = current - this.perPage

      // 表示する分を調整
      return returnArray.slice(start, current)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .CP008_headers {
    resize: horizontal;
    overflow: hidden;
  }

  .CP008_showData {
    background-color: rgb(255, 255, 255);
    margin: 0px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .CP008_showHeader {
    background-color: #0737d3;
    color: white;
    white-space: nowrap;
    cursor: pointer;
  }

  .CP008_TableDataView {
    text-align: center;
  }

  .CP008_pager {
    width: 100%;
    border: solid 0px;
    text-align: left;
  }
  .CP008_pagerTable{
    height: 35px;
  }

  .CP008_pageSelector {
    width: 500px;
    border: 0px;
  }

  .CP008_tableData{
    margin: 0px auto;
    margin-top: 20px;
    border: solid 2px;
    background-color: rgb(255, 255, 255);
    border-collapse: collapse;
  }

  th, td {
    height: 20px;
    padding: 0 5px;
    padding-top: 2px;
    margin: 0px;
    border: 1px solid #ccc;
    text-align: left;
    font-size: 12px;
    vertical-align: center;
  }

  .CP008_spaceColumnRight {
    width: 150px;
    text-align: right;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }
  .CP008_spaceColumnCenter {
    width: 500px;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }
  .CP008_spaceColumnLeft {
    width: 150px;
    text-align: left;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }

  .CP008_previousPage {
    width: 30px;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }
  .CP008_nextPage {
    width: 30px;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }

  .CP008_firstPage {
    width: 30px;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }
  .CP008_lastPage {
    width: 30px;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }

  .CP008_pageShow {
    text-align: center;
    padding: 0px 5px;
    margin: 0px;
    border: 0px;
  }

  .CP008_indicator {
    float: right;
    text-align: left;
    padding: 0px;
    margin: 0px;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }
</style>
