<!---
引数(props):
  isVisible(必須) : ボックスの出し入れ。デフォルトはfalse。closeイベントを受信後falseにすることでボックスを閉じる
  message         : 表示するメッセージ
  title           : 表示タイトル
  btn1Text        : ボタン1の文字列(デフォルトOK) 指定なしでも必ず表示される
  btn2Text        : ボタン2の文字列() 表示しない場合は指定を行わないこと
  btn3Text        : ボタン3の文字列() 表示しない場合は指定を行わないこと
  btn1Color       : ボタン1の背景色(colorの文字列に準ずる) default:#eee
  btn2Color       : ボタン2の背景色(colorの文字列に準ずる) default:#eee
  btn3Color       : ボタン3の背景色(colorの文字列に準ずる) default:#eee
  btn1TextColor   : ボタン1の文字色(colorの文字列に準ずる) default:#000
  btn2TextColor   : ボタン2の文字色(colorの文字列に準ずる) default:#000
  btn3TextColor   : ボタン3の文字色(colorの文字列に準ずる) default:#000
  messageType     : メッセージボックス種別。0:information 1:warning 2:error default:0
-->
<template>
  <teleport to="body">
    <div class="CP005_modal" id="CP005_modal" v-show="isVisible"></div>
    <div class="CP005_modal_content" v-show="isVisible">
      <div class="CP005_TitleBar">{{ title }}</div>
      <table class="CP005_Message">
        <tr>
          <td class="CP005_MessageIcon">
            <img v-show="iconType==='0'" src="../assets/information.png">
            <img v-show="iconType==='1'" src="../assets/warning.png">
            <img v-show="iconType==='2'" src="../assets/error.png">
          </td>
          <td>
            <p v-html="message"></p>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <button :style="Color1Style" class="CP005_btnEdit1" @click="close1">{{ btn1Text }}</button>
          </td>
          <td v-show="isButton2Show">
            <button :style="Color2Style" class="CP005_btnEdit2" @click="close2">{{ btn2Text }}</button>
          </td>
          <td v-show="isButton3Show">
            <button :style="Color3Style" class="CP005_btnEdit3" @click="close3">{{ btn3Text }}</button>
          </td>
        </tr>
      </table>
    </div>
  </teleport>
</template>

<script>
import Common from '../modules/common.js'
const componentID = 'CP005'

export default {
  name: 'ModalBox',
  emits: ['close'],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    message: String,
    title: String,
    btn1Text: {
      type: String,
      default: 'OK'
    },
    btn2Text: {
      type: String,
      default: ''
    },
    btn3Text: {
      type: String,
      default: ''
    },
    btn1Color: {
      type: String,
      default: '#eee'
    },
    btn2Color: {
      type: String,
      default: '#eee'
    },
    btn3Color: {
      type: String,
      default: '#eee'
    },
    btn1TextColor: {
      type: String,
      default: '#000'
    },
    btn2TextColor: {
      type: String,
      default: '#000'
    },
    btn3TextColor: {
      type: String,
      default: '#000'
    },
    messageType: {
      type: String,
      default: '0' // 0: Information 1: Warning 2: Error
    }
  },
  // 動的HTML設定を行うため、computedを使用する。dataでは反応しない。
  computed: {
    // ボタン2にTEXTのセットがない場合、ボタンを非表示にする
    isButton2Show () {
      return (this.btn2Text !== '')
    },
    // ボタン3にTEXTのセットがない場合、ボタンを非表示にする
    isButton3Show () {
      return (this.btn3Text !== '')
    },
    // アイコン種別をセットする
    iconType () {
      return this.messageType
    },
    Color1Style () {
      return 'background-color: ' + this.btn1Color + ';' + 'color: ' + this.btn1TextColor + ';'
    },
    Color2Style () {
      return 'background-color: ' + this.btn2Color + ';' + 'color: ' + this.btn2TextColor + ';'
    },
    Color3Style () {
      return 'background-color: ' + this.btn3Color + ';' + 'color: ' + this.btn3TextColor + ';'
    }
  },
  methods: {
    // ボタン1を押下したとき親コンポーネントに"0"返す
    close1 () {
      this.$emit('close', '0')
    },
    // ボタン1を押下したとき親コンポーネントに"1"返す
    close2 () {
      this.$emit('close', '1')
    },
    // ボタン1を押下したとき親コンポーネントに"2"返す
    close3 () {
      this.$emit('close', '2')
    }
  }
}
</script>

<style scoped>
  .CP005_modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .CP005_modal_content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #000000;
    width: auto;
    height: auto;
    padding: 2px;
    font-size: 12px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .CP005_TitleBar{
    background-color: #001aff;
    width: 100%;
    color:white;
    font-size: 14px;
    padding: 1px 1px;
    margin: 0px;
    border: 0px;
  }
  .CP005_MessageIcon{
    text-align: left;
    vertical-align: top;
    width: 30px;
    padding-top: 12px;
  }
  .CP005_Message {
    width: 100%;
  }
  .CP005_btnEdit1 {
    padding: 3px 15px;
    height: 30px;
    border: solid 1px #000000;
  }
  .CP005_btnEdit1:hover {
    background-color: #000000;
    color: rgb(253, 255, 153);
    box-shadow: 0px 0px 5px 2px #eaff9f;
  }

  .CP005_btnEdit2 {
    padding: 3px 15px;
    height: 30px;
    border: solid 1px #000000;
  }

  .CP005_btnEdit2:hover {
    background-color: #000000;
    color: rgb(253, 255, 153);
    box-shadow: 0px 0px 5px 2px #eaff9f;
  }
  .CP005_btnEdit3 {
    padding: 3px 15px;
    height: 30px;
    border: solid 1px #000000;
  }

  .CP005_btnEdit3:hover {
    background-color: #000000;
    color: rgb(253, 255, 153);
    box-shadow: 0px 0px 5px 2px #eaff9f;
  }
</style>
