import { createRouter, createWebHistory } from 'vue-router'
import UserLogin from '../../../shared/views/UserLogin.vue'
import Auth from '../../../shared/views/Auth.vue'
import ReportList from '../../../shared/views/ReportList.vue'
import PasswordReset from '../../../shared/views/PasswordReset.vue'
import ResetAuth from '../../../shared/views/ResetAuth.vue'
import AppendNewUser2 from '../../../shared/views/AppendNewUser2.vue'
import EditProfile from '../../../shared/views/EditProfile.vue'
import UserDelete from '../../../shared/views/UserDelete.vue'
import AuthDelete from '../../../shared/views/AuthDelete.vue'
import SetMailAddress from '../../../shared/views/SetMailAddress.vue'
import SetPassword from '../../../shared/views/SetPassword.vue'
import SetUserName from '../../../shared/views/SetUserName.vue'
import AuthFromMail from '../../../shared/views/AuthFromMail.vue'
import SessionTimeOut from '../../../shared/views/SessionTimeOut.vue'
import AccessDenied from '../../../shared/views/AccessDenied.vue'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: UserLogin
  },
  {
    path: '/AccessDenied',
    name: 'AccessDenied',
    component: AccessDenied
  },
  {
    path: '/SessionTimeOut',
    name: 'SessionTimeOut',
    component: SessionTimeOut
  },
  {
    path: '/AuthFromMail',
    name: 'AuthFromMail',
    component: AuthFromMail
  },
  {
    path: '/UserDelete',
    name: 'UserDelete',
    component: UserDelete
  },
  {
    path: '/AuthDelete',
    name: 'AuthDelete',
    component: AuthDelete
  },
  {
    path: '/EditProfile',
    name: 'EditProfile',
    component: EditProfile
  },
  {
    path: '/Auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/ReportList',
    name: 'ReportList',
    component: ReportList
  },
  {
    path: '/PasswordReset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/ResetAuth',
    name: 'ResetAuth',
    component: ResetAuth
  },
  {
    path: '/AppendNewUser2',
    name: 'AppendNewUser2',
    component: AppendNewUser2
  },
  {
    path: '/SetMailAddress',
    name: 'SetMailAddress',
    component: SetMailAddress
  },
  {
    path: '/SetPassword',
    name: 'SetPassword',
    component: SetPassword
  },
  {
    path: '/SetUserName',
    name: 'SetUserName',
    component: SetUserName
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
