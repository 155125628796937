<template>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度やり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibliePolicyError"
    message="入力したパスワードが、パスワードポリシーを満たしていません。<br>最低10文字で大文字、小文字、数字、および特殊文字を含めてください。"
    title="ポリシー違反"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogPolicyError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisiblieCompleteError"
    :message="CompleteErrorMessage"
    title="登録処理エラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogCompleteError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputError1"
    message="全ての項目を入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputError1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputPassword"
    message="パスワードとパスワード(確認用)が一致していません。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputPassword"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleMessage1"
    message="サインインIDの登録が完了しました。"
    title="登録完了"
    btn1Text="OK"
    messageType="0"
    @close="closeDialog1"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="新規ユーザー情報を登録中です。"
    title="登録中"
  ></ProgressBox>
  <MenuPanel PageName="新規ユーザー情報登録" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <table>
        <tr>
          <td>
            <p>ユーザー名:</p>
          </td>
          <td>
            <LabelBox id="UserName" autocomplete="text" TypeName="text" name="userName" oncontextmenu="return true" maxlength="50"></LabelBox>
          </td>
        </tr>
        <tr>
          <td>
            <p>パスワード:</p>
          </td>
          <td>
            <LabelBox autocomplete="text" @input="checkPasswordPolicy" id="Password" TypeName="password" name="password" oncontextmenu="return false" maxlength="20"></LabelBox>
          </td>
        </tr>
        <tr>
          <td>
            <p>パスワード(確認用):</p>
          </td>
          <td>
            <LabelBox autocomplete="text" id="RePassword" TypeName="password" name="password" oncontextmenu="return false" maxlength="20"></LabelBox>
          </td>
        </tr>
      </table>
      <CheckPassword ref="PasswordCheck"></CheckPassword>
      <hr>
      <div class="ApplyPassword">
        <ButtonBox name="ApplyPassword" ButtonName="登録" @click="ApplyUser()"></ButtonBox>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import CommonModule from '../modules/commonCognito.js'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'
import CheckPassword from '../components/PasswordCheck.vue'

export default {
  name: 'ResetAuthView',
  components: {
    LabelBox,
    ButtonBox,
    // SelectBox,
    MenuPanel,
    ModalBox,
    ProgressBox,
    CheckPassword
  },
  data () {
    return {
      // ここはなんかデータベース的なものから取得するようにすればいいかも
      SendTypeValues: ['Eメール', 'SMS'],
      isVisibleMessage1: false,
      isVisibleInputError1: false,
      isVisibleInputPassword: false,
      isVisiblieCompleteError: false,
      CompleteErrorMessage: '',
      isVisibleTimeout: false,
      isVisibleProgress1: false,
      isVisibliePolicyError: false
    }
  },
  methods: {
    closeDialogPolicyError () {
      this.isVisibliePolicyError = false
    },
    checkPasswordPolicy () {
      const password = document.getElementById("Password").value
      this.$refs.PasswordCheck.checkPasswordPolicy(password)
    },
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
    },
    closeDialogCompleteError (e) {
      this.isVisiblieCompleteError = false
    },
    closeDialogInputError1 (e) {
      this.isVisibleInputError1 = false
    },
    closeDialogInputPassword (e) {
      this.isVisibleInputPassword = false
    },
    async ApplyUser () {
      const username = document.getElementById('UserName').value
      const password1 = document.getElementById('Password').value
      const password2 = document.getElementById('RePassword').value
      const userid = sessionStorage.getItem("userid")

      // 全ての情報が登録されていない場合はエラー
      if (username === '' ||
          password1 === '' ||
          password2 === '') {
        this.isVisibleInputError1 = true
        return
      }

      // 2つの入力情報が一致していない場合はエラー
      if (password1 !== password2) {
        this.isVisibleInputPassword = true
        return
      }

      // パスワードポリシーチェック
      if (!this.$refs.PasswordCheck.fullCompleted()) {
        this.isVisibliePolicyError = true
        return
      }
      this.isVisibleProgress1 = true
      // Cognito & RDSの更新を実施
      const [status, body] = await this.updateUserInformarionforRDS(userid, password1, username)
      this.isVisibleProgress1 = false

      // 更新処理がOKであれば、サインイン画面へ遷移する。
      if (status === 200) {
        // this.$router.push({ name: 'CompleteApply' })
        this.isVisibleMessage1 = true
      } else if (status === 510) {
        this.isVisibleTimeout = true
        return
      } else {
        // エラー詳細については深く語らない。
        this.CompleteErrorMessage = "新規ユーザー情報の登録に失敗しました。<br>" +
                                    "しばらく待ってからもう一度やり直してください。<br><br>" +
                                    "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                                    "Message:" + body
        this.isVisiblieCompleteError = true
      }
    },
    async updateUserInformarionforRDS(userid, password, username) {
      //const url = 'https://fiplrjwav3.execute-api.ap-northeast-1.amazonaws.com/alpha2'
      const url = URLS.registNewUser_updateUserInfotmation[Common.RELEASE]
      const requestBody = {
        userid: userid,
        password: password,
        username: username
      }

      const response =  await Common.POSTRequestWithoutAuth(url, requestBody)
      const result = await Common.POSTResponse(response, this)
      return result
    },
    closeDialog1 (e) {
      sessionStorage.clear()
      CommonModule.CognitoSignout()
      this.$router.replace({ name: 'Login' })
    }
  },
  computed: {

  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  td,tr {
    text-align: left;
    margin: 0px;
    padding: 0px;
    line-height: 0.5;
  }

  .ApplyPassword {
    text-align: right;
    margin-right: 30px;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 500px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }

  .passwordReset {
    font-size: 12px;
  }

</style>
