// URL 0:PMG開発用 1:本番用 2:リグレ用
// 開発環境の構築が出来次第、URLを入れる感じ
// Common.jsのRELEASEで制御する。

// 請求先ユーザー仮登録の期限チェック(getUserStatus) 2023/06/22 追加
const userLogin_getUserRegistable = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/getUserStatus/',  // PMG開発
  'https://api-client.reps.tcs-s.jp/getUserStatus/',                  // 本番
  'https://api-client.staging.reps.tcs-s.jp/getUserStatus/'           // リグレ環境
]

// 請求先ユーザーMFA認証確認(test-respondToAuthChallenge) 2023/06/12 追加
const auth_MFAVerification = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/respondToAuthChallenge/', // PMG開発
  'https://api-client.reps.tcs-s.jp/respondToAuthChallenge/',                 // 本番
  'https://api-client.staging.reps.tcs-s.jp/respondToAuthChallenge/'          // リグレ環境
]

// 請求先ユーザーサインイン(test-Cognito-Mfa-InitiateAuth) 2023/06/12 追加
const userLogin_CheckUserIDAndPassword = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/MfaSignIn/', // PMG開発
  'https://api-client.reps.tcs-s.jp/MfaSignIn/',                 // 本番
  'https://api-client.staging.reps.tcs-s.jp/MfaSignIn/'          // リグレ環境
]

// 帳票ファイル削除処理(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-deleteInvoiceFile) 2023/06/09 追加
const reportList_deleteFile = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/deleteInvoiceFile/', // PMG開発
  'https://api-admin.reps.tcs-s.jp/deleteInvoiceFile/',                 // 本番
  'https://api-admin.staging.reps.tcs-s.jp/deleteInvoiceFile/'          // リグレ環境
]

// サインイン時ユーザー情報の取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getUserInformation)
const userLogin_getUserInformation = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/getUserInformation/',
  'https://api-client.reps.tcs-s.jp/getUserInformation/',
  'https://api-client.staging.reps.tcs-s.jp/getUserInformation/'
]

// サインイン時ユーザー情報の取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getUserInformation)-admin
const userLogin_getUserInformation_Admin = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getUserInformationAdmin/',
  'https://api-admin.reps.tcs-s.jp/getUserInformationAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/getUserInformationAdmin/'
]

// 請求先ユーザー一覧取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getClientUserList)
const userList_getUserList =  [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getClientUserList/',
  'https://api-admin.reps.tcs-s.jp/getClientUserList/',
  'https://api-admin.staging.reps.tcs-s.jp/getClientUserList/'
]

// 請求先ユーザー削除(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-deleteClientUser)
const userEdit_deleteUser = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/deleteClientUser/',
  'https://api-client.reps.tcs-s.jp/deleteClientUser/',
  'https://api-client.staging.reps.tcs-s.jp/deleteClientUser/'
]

// 請求先ユーザー削除(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-deleteClientUser)-admin
const userEdit_deleteUser_admin= [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/deleteClientUserAdmin/',
  'https://api-admin.reps.tcs-s.jp/deleteClientUserAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/deleteClientUserAdmin/'
]

// サインインユーザー名更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateUserName)
const setUserName_updateUserName = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/updateUserName/',
  'https://api-client.reps.tcs-s.jp/updateUserName/',
  'https://api-client.staging.reps.tcs-s.jp/updateUserName/'
]

// サインインユーザー名更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateUserName)-admin
const setUserName_updateUserName_admin = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/updateUserNameAdmin/',
  'https://api-admin.reps.tcs-s.jp/updateUserNameAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/updateUserNameAdmin/'
]

// PDFファイルの署名付きリンクを取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getInvoiceFile)
const reportList_getFileLink = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/getInvoiceFile/',
  'https://api-client.reps.tcs-s.jp/getInvoiceFile/',
  'https://api-client.staging.reps.tcs-s.jp/getInvoiceFile/'
]

// PDFファイルの署名付きリンクを取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getInvoiceFile)-admin
const reportList_getFileLink_admin = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getInvoiceFileAdmin/',
  'https://api-admin.reps.tcs-s.jp/getInvoiceFileAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/getInvoiceFileAdmin/'
]

// 請求書一覧の検索パラメーター取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getInvoiceListParam)
const reportList_getParams = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getInvoiceListParam/',
  'https://api-admin.reps.tcs-s.jp/getInvoiceListParam/',
  'https://api-admin.staging.reps.tcs-s.jp/getInvoiceListParam/'
]

// 帳票一覧取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getInvoiceList)
const reportList_getInvoiceList = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/getInvoiceList/',
  'https://api-client.reps.tcs-s.jp/getInvoiceList/',
  'https://api-client.staging.reps.tcs-s.jp/getInvoiceList/'
]

// 帳票一覧取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getInvoiceList)-admin
const reportList_getInvoiceList_admin = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getInvoiceListAdmin/',
  'https://api-admin.reps.tcs-s.jp/getInvoiceListAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/getInvoiceListAdmin/'
]

// 新規管理者ユーザーのパスワード更新とステータスの更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-RegistAdminUser)
const passwordChange_updatePasswordAndStatus = [
	'https://api-admin.poc.pmg-prototype.ricoh.co.jp/RegistAdminUser/',
	'https://api-admin.reps.tcs-s.jp/RegistAdminUser/',
  'https://api-admin.staging.reps.tcs-s.jp/RegistAdminUser/'
]

// サインインユーザーのメールアドレス更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateMailAddress)
const authFromMail_updateMailAddress = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/updateMailAddress/',
  'https://api-client.reps.tcs-s.jp/updateMailAddress/',
  'https://api-client.staging.reps.tcs-s.jp/updateMailAddress/'
]

// サインインユーザーのメールアドレス更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateMailAddress)-admin
const authFromMail_updateMailAddress_admin = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/updateMailAddressAdmin/',
  'https://api-admin.reps.tcs-s.jp/updateMailAddressAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/updateMailAddressAdmin/'
]

// サインインユーザーの最終サインイン時間更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateLastSignIn)
const auth_updateLastSignIn = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/updateLastSignIn/',
  'https://api-client.reps.tcs-s.jp/updateLastSignIn/',
  'https://api-client.staging.reps.tcs-s.jp/updateLastSignIn/'
]

// サインインユーザーの最終サインイン時間更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateLastSignIn)-admin
const auth_updateLastSignIn_admin = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/updateLastSignInAdmin/',
  'https://api-admin.reps.tcs-s.jp/updateLastSignInAdmin/',
  'https://api-admin.staging.reps.tcs-s.jp/updateLastSignInAdmin/'
]

// 請求先ユーザー情報更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateNameAndPass)
const registNewUser_updateUserInfotmation = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/updateNameAndPass/',
  'https://api-client.reps.tcs-s.jp/updateNameAndPass/',
  'https://api-client.staging.reps.tcs-s.jp/updateNameAndPass/'
]

// 新規WEBユーザー申請処理(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-createNewUser)
const createNewUser_newUserSignUp = [
  'https://api-client.poc.pmg-prototype.ricoh.co.jp/createNewUser/',
  'https://api-client.reps.tcs-s.jp/createNewUser/',
  'https://api-client.staging.reps.tcs-s.jp/createNewUser/'
]

// 請求元ユーザー一覧取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getAdminUserList)
const adminUserList_getUserList = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getAdminUserList/',
  'https://api-admin.reps.tcs-s.jp/getAdminUserList/',
  'https://api-admin.staging.reps.tcs-s.jp/getAdminUserList/'
]

// 請求元ユーザー追加(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-createAdminUser)
const adminUserEdit_appendUser = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/createAdminUser/',
  'https://api-admin.reps.tcs-s.jp/createAdminUser/',
  'https://api-admin.staging.reps.tcs-s.jp/createAdminUser/'
]

// 請求元ユーザー削除(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-deleteAdminUser)
const adminUserEdit_deleteUser = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/deleteAdminUser/',
  'https://api-admin.reps.tcs-s.jp/deleteAdminUser/',
  'https://api-admin.staging.reps.tcs-s.jp/deleteAdminUser/'
]

// 請求元ユーザー更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateAdminUser)
const adminUserEdit_updateUser = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/updateAdminUser/',
  'https://api-admin.reps.tcs-s.jp/updateAdminUser/',
  'https://api-admin.staging.reps.tcs-s.jp/updateAdminUser/'
]

// 請求先情報一覧取得(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-getClientList)
const accountList_getAccountList = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/getClientList/',
  'https://api-admin.reps.tcs-s.jp/getClientList/',
  'https://api-admin.staging.reps.tcs-s.jp/getClientList/'
]

// 請求先情報更新(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-updateClient)
const accountEdit_updateClient = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/updateClient/',
  'https://api-admin.reps.tcs-s.jp/updateClient/',
  'https://api-admin.staging.reps.tcs-s.jp/updateClient/'
]

// 請求先情報削除(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-deleteClient)
const accountEdit_deleteClient = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/deleteClient/',
  'https://api-admin.reps.tcs-s.jp/deleteClient/',
  'https://api-admin.staging.reps.tcs-s.jp/deleteClient/'
]

// 請求先情報追加(tcsReportDXBaseSystem-dev-reportViewWeb-lmd-appendClient)
const accountEdit_appendClient = [
  'https://api-admin.poc.pmg-prototype.ricoh.co.jp/appendClient/',
  'https://api-admin.reps.tcs-s.jp/appendClient/',
  'https://api-admin.staging.reps.tcs-s.jp/appendClient/'  // カスタムドメイン固まり次第修正
]

export default {
  userLogin_getUserInformation,
  userList_getUserList,
  userEdit_deleteUser,
  setUserName_updateUserName,
  reportList_getFileLink,
  reportList_getParams,
  reportList_getInvoiceList,
  passwordChange_updatePasswordAndStatus,
  authFromMail_updateMailAddress,
  auth_updateLastSignIn,
  registNewUser_updateUserInfotmation,
  createNewUser_newUserSignUp,
  adminUserList_getUserList,
  adminUserEdit_appendUser,
  adminUserEdit_deleteUser,
  adminUserEdit_updateUser,
  accountList_getAccountList,
  accountEdit_updateClient,
  accountEdit_deleteClient,
  accountEdit_appendClient,
  reportList_deleteFile,
  userLogin_CheckUserIDAndPassword,
  auth_MFAVerification,
  userLogin_getUserRegistable,
  auth_updateLastSignIn_admin,
  userLogin_getUserInformation_Admin,
  reportList_getInvoiceList_admin,
  authFromMail_updateMailAddress_admin,
  reportList_getFileLink_admin,
  setUserName_updateUserName_admin,
  userEdit_deleteUser_admin
}