<template>
  <div class="CP004_MenuMain">
    <table class="CP004_Menu">
      <tr>
        <td class="CP004_leftParts">
          <table class="CP004_CampanyParts">
            <td class="CP004_logoImage">
              <img class="CP004_logoImageShow" src="../assets/tmn_cs_symbol_v_1_rgb_s.jpg" @click="returnHome">
            </td>
            <td class="CP004_CampanylogoImage">
              <img class="CP004_logoImageShow" src="../assets/05_TCS_logo.gif">
            </td>
            <td class="CP004_CampanyName">　Web帳票サービス</td>
          </table>
        </td>
        <td class="CP004_CenterParts">{{ PageName }}</td>
        <td class="CP004_RightParts">
          <div class="CP004_RightPartsContainer">
            <div v-if="showUserName" class="CP004_LoginUserName">{{ UserName }}</div>
            <div>
              <div class="CP004_RightPartsTable">
                <button v-if="showUserEditButton" class="CP004_profileButton" type="button" name="profileBtn" @click="profileShow">ユーザー情報編集</button>
                <button v-if="showLogoutButton" class="CP004_logoutButton" type="button" name="logoutBtn" @click="logout">サインアウト</button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="CP004_Message" v-if="showMessage">
      <p>お知らせ</p>
      <p v-html="Message"></p>
    </div>
  </div>
</template>

<script>
import CommonModule from '../modules/commonCognito.js'
import Common from '../modules/common.js'

const componentID = 'CP004'

export default {
  name: 'MenuPanel',
  data () {
    const username = sessionStorage.getItem('username')
    const showuser = !(username === "undefined")
    return {
      UserName: username,
      Message: '<br><br><br>',
      showUserName: showuser
    }
  },
  props: {
    PageName: String,
    showLogout: {
      type: String,
      default: 'true'
    },
    showUserEdit: {
      type: String,
      default: 'true'
    },
    showMessage: {
      type: Boolean,
      default: false
    }/*,
    HomeJump: {
      type: Boolean,
      default: true
    }*/
  },
  methods: {
    logout () {
      CommonModule.CognitoSignout()
      sessionStorage.clear()
      this.$router.replace({ name: 'Login' })
    },
    profileShow () {
      if (!CommonModule.checkSession(this)) return
      
      // 自身のユーザー情報の編集画面を開く際、現在のページを保持(戻るため)
      sessionStorage.setItem('setBeforeEditProfile', location.pathname)

      // 更新処理を行うのは、画面の先で。
      this.$router.replace('/EditProfile')
    },
    returnHome () {
      if (this.HomeJump) {
        if (Common.isAdminUser()) {
          this.$router.replace('/MaintenanceMenu')
        } else {
          this.$router.replace('/ReportList')
        }
      }
    }
  },
  mounted () {
    Common.logPrint("メッセージファイル読み込み")
    if (!this.showMessage) return
    fetch('message.txt') 
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          // 403 Forbidden エラーの場合の処理
          Common.logPrint("メッセージファイルが存在しなかった場合の処理")
          this.Message = "<br><br><br>"
        }
      })
      .then(data => {
        if (data) {
          this.Message = data; // テキストデータを Vue データに設定
        }
      })
      .catch(error => {
        Common.logPrint("メッセージファイルが存在しなかった場合の処理2")
      })
  },
  computed: {
    showLogoutButton () {
      return (this.showLogout === 'true')
    },
    showUserEditButton () {
      return (this.showUserEdit === 'true')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .CP004_MenuMain {
    margin: auto;
    background-color: cornsilk;
    width: 100%;
    border: solid 2px;
    /* border-color: burlywood; */
    border-color: cornsilk;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px #000000;
  }
  .CP004_RightPartsContainer{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow: visible; /* 親要素のはみ出し許可 */
  }
  .CP004_RightPartsTable{
    display: flex;
    justify-content: flex-end;
  }
  .CP004_CampanyParts {
    flex-direction: column;
    justify-content: flex-end;
  }

  .CP004_Menu {
    margin: 0px;
    padding: 0px;
    border: 0px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  .CP004_logoImage{
    padding: 0px;
    margin: 0px;
    width: 50px;
    text-align: left;
  }

  .CP004_CampanylogoImage{
    padding: 0px;
    margin: 0px;
    width: 150px;
    text-align: left;
    vertical-align: middle;
  }

  .CP004_LoginUserName {
    margin: 0px;
    color: black;
    font-size: 12px;
    padding: 5px 0px;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }

  .CP004_Message {
    width: 100%;
    background-color: white;
    margin: 0px;
  }

  .CP004_leftParts {
    padding: 0px;
    margin: 0px;
    width: 39%;
    text-align: left;
  }

  .CP004_CampanyName {
    padding: 0px;
    vertical-align: middle;
    font-size:12px;
  }

  .CP004_CenterParts {
    margin: 0px;
    padding: 0px;
    width: 22%;
    font-size: 18px;
    text-align: center;
  }

  .CP004_RightParts {
    margin: 0px;
    padding: 0px;
    width: 39%;
    text-align: right;
  }

  .CP004_logoutButton {
    background-color: #771515;
    color: rgb(255, 255, 255);
    font-size: 12px;
    margin-right: 5px;
    border: 0px;
    border-radius: 3px;
    padding: 5px 20px;
    width: 125px;
    height: 25px;
  }
  .CP004_logoutButton:hover {
    background-color: #fd4646;
    box-shadow: 0px 0px 5px 2px #ff9191;
    color: rgb(0, 0, 0);
    font-size: 12px;
    border: 0px;
    border-radius: 3px;
    width: 125px;
    height: 25px;
    padding: 5px 20px;
  }

  .CP004_profileButton {
    text-decoration:none;
    background-color: #196736;
    color: rgb(255, 255, 255);
    font-size: 12px;
    margin-right: 5px;
    border: 0px;
    border-radius: 3px;
    width: 140px;
    height: 25px;
    padding: 5px 20px;
  }
  .CP004_profileButton:hover {
    text-decoration:none;
    background-color: #25ff5b;
    box-shadow: 0px 0px 5px 2px #17aa54;
    color: rgb(0, 0, 0);
    font-size: 12px;
    border: 0px;
    border-radius: 3px;
    width: 140px;
    height: 25px;
    padding: 5px 20px;
  }

  .CP004_RightButton {
    display: flex;
    text-align: right;
    justify-content: flex-end;
  }

  .CP004_logoImageShow {
    width: 100%;
  }
</style>
