<template>
  <ModalBox
    :isVisible="isVisibleBeforePasswordError"
    message="旧パスワードに誤りがあります。<br>もう一度確認してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogBeforePasswordError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisiblePasswordPolicyError"
    :message="PasswordPolicyErrorMessage"
    title="パスワード更新エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogPasswordPolicyError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputError1"
    message="全ての項目を入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputError1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputPassword"
    message="新パスワードと新パスワード(確認用)が一致していません。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputPassword"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="パスワードの更新中です。"
    title="更新中"
  ></ProgressBox>
  <MenuPanel PageName="パスワードの変更" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <form>
        <table>
          <tr>
            <td>
              <p class="required">旧パスワード:</p>
            </td>
            <td>
              <LabelBox id="OldPassword" TypeName="password" name="OldPassword" oncontextmenu="return false" maxlength="20"></LabelBox>
            </td>
          </tr>
          <tr>
            <td>
              <p class="required">新パスワード:</p>
            </td>
            <td>
              <LabelBox @input="checkPasswordPolicy" id="Password" TypeName="password" name="Password" oncontextmenu="return false" maxlength="20"></LabelBox>
            </td>
          </tr>
          <tr>
            <td>
              <p class="required">新パスワード(確認用):</p>
            </td>
            <td>
              <LabelBox id="RePassword" TypeName="password" name="RePassword" oncontextmenu="return false" maxlength="20"></LabelBox>
            </td>
          </tr>
        </table>
      </form>
      <CheckPassword ref="PasswordCheck"></CheckPassword>
      <hr>
      <div class="Apply">
        <ButtonBox name="returnBefore" ButtonName="戻る" @click="returnBefore()"></ButtonBox>
        <ButtonBox name="Apply" ButtonName="変更" @click="Apply()"></ButtonBox>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import commonCognito from '../modules/commonCognito.js'
import CheckPassword from '../components/PasswordCheck.vue'

export default {
  name: 'SetPassword',
  data () {
    return {
      isVisibleInputError1: false,
      isVisibleProgress1: false,
      isVisibleInputPassword: false,
      isVisibleBeforePasswordError: false,
      PasswordPolicyErrorMessage: '',
      isVisiblePasswordPolicyError: false
    }
  },
  components: {
    LabelBox,
    ButtonBox,
    MenuPanel,
    ModalBox,
    ProgressBox,
    CheckPassword
  },
  methods: {
    checkPasswordPolicy () {
      const password = document.getElementById("Password").value
      this.$refs.PasswordCheck.checkPasswordPolicy(password)
    },
    closeDialogPasswordPolicyError () {
      this.isVisiblePasswordPolicyError = false
    },
    closeDialogBeforePasswordError () {
      this.isVisibleBeforePasswordError = false
    },
    closeDialogInputPassword (e) {
      this.isVisibleInputPassword = false
    },
    closeDialogInputError1 (e) {
      this.isVisibleInputError1 = false
    },
    async returnBefore () {
      // セッションチェック
      if(!commonCognito.checkSession(this)) return
      const beforePage = await sessionStorage.getItem('BeforePassword')
      this.$router.replace(beforePage)
    },
    BeforeCheck () {
      const pass1 = document.getElementById('Password').value
      const pass2 = document.getElementById('RePassword').value

      // const userid = sessionStorage.getItem("userid")
      // 全ての情報が登録されていない場合はエラー
      if (pass1 === '' ||
          pass2 === '') {
        this.isVisibleInputError1 = true
        return false
      }

      // 2つの入力情報が一致していない場合はエラー
      if (pass1 !== pass2) {
          this.isVisibleInputPassword = true
          return false
      }
      
      // パスワードポリシーチェック
      if (!this.$refs.PasswordCheck.fullCompleted()) {
        this.PasswordPolicyErrorMessage = "入力したパスワードが、パスワードポリシーを満たしていません。<br>" +
                                          "最低10文字で大文字、小文字、数字、および特殊文字を含めてください。"
        this.isVisiblePasswordPolicyError = true
        return false
      }
      return true
    },
    async Apply () {
      // セッションチェック
      if(!commonCognito.checkSession(this)) return
      const password = document.getElementById('Password').value
      const beforePassword = document.getElementById('OldPassword').value

      // 事前項目チェック
      if (!this.BeforeCheck()) return
      
      // 認証コードをSMS電話番号に対して発行する処理を行う
      this.isVisibleProgress1 = true
      const [result, msg] = await commonCognito.CognitoChangePassword(beforePassword, password)
      this.isVisibleProgress1 = false

      //Common.logPrint(result)
      if (result !== 0){
        switch(result) {
          // 前パスワードに誤りがある
          case 1:
            this.isVisibleBeforePasswordError = true
            break       
          
          // パスワードポリシーに一致していない(前処理ではじかれると思う)
          case 2:
            this.PasswordPolicyErrorMessage = "入力したパスワードが、パスワードポリシーを満たしていません。<br>" +
                                              "最低10文字で大文字、小文字、数字、および特殊文字を含めてください。"
            this.isVisiblePasswordPolicyError = true
            break       
        
           // パスワードの長さ足らない(前処理ではじかれると思う)
           case 3:
            this.PasswordPolicyErrorMessage = "入力したパスワードが短すぎます。"
            this.isVisiblePasswordPolicyError = true
            break     

            // それ以外のエラー
          default:
            this.PasswordPolicyErrorMessage = "パスワード変更処理でエラーが発生しました。<br>" + 
                                              "しばらく待ってからもう一度やり直してください。<br><br>" +
                                              "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                                              "Message:" + msg
            this.isVisiblePasswordPolicyError = true
            break       
        }
        return
      } else {
        this.isVisibleProgress1 = false
        this.returnBefore()
      }
    },
  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  .attention {
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 15px;
    color:#ff3838;
    line-height: 1.2;
  }

  td,tr {
    text-align: left;
    margin: 0px;
    padding: 0px;
    line-height: 0.5;
  }

  .Apply {
    text-align: right;
    margin-right: 30px;
  }


  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 600px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }

  .required::after {
    background-color: red;
    color: white;
    text-align: left;
    content:"\5fc5\9808";
    border-radius:3px;
    font-size: 8px;
    padding: 3px;
    margin: 2px;
    vertical-align: middle;
  }
</style>
