const DEBUG = false                           // リリース時はfalseにしよう。
const VulnerabilityAssessment = false         // 脆弱性診断用ならtrue 
const UPDATE_TIMESTAMP = "2023/11/27 17:00"   // 更新日時を記載します。3画面の表紙に乗ります。
const RELEASE = 1                             // 0:開発 1:本番 2:開発検証環境 (リリース時には確認すること！！)
const mailaddressPattern = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

// エラー発生時の文字列から、ErrorCodeを取得する
// 主にAPI GatewayからのResponseを確認するときに使用する
async function getErrorCodeFromResponceBody (body) {
  const Body = await getReadStream(body)
  const errCode = Body.split('/')[0].replace('"', '')
  return Number(errCode)
}

function checkMailAddress(mailaddress) {
  if (mailaddressPattern.test(mailaddress)) {
    return true
  } else {
    return false
  }
}

function ModuleStatus () {
  console.log("----------------------------------")
  if (DEBUG) {
    console.log("デバッグモード: ON")
  } else {
    console.log("デバッグモード: OFF")
  }

  switch (RELEASE) {
    case 0:
      console.log("接続環境　　　: PMG開発環境")
      break
    case 1:
      console.log("接続環境　　　: 本番環境")
      break
    case 2:
      console.log("接続環境　　　: 開発検証環境")
      break
  }

  if (VulnerabilityAssessment) {
    console.log("Cognito認証　: 脆弱性診断用スキップ")
  } else {
    console.log("Cognito認証　: 通常")
  }
  console.log("更新日時　　　: " + UPDATE_TIMESTAMP)
  console.log("----------------------------------")
}

async function getRequestResponse(url, postData = null) {
  let response
  try {
    if (postData === null) {
      logPrint('GET')
      response = await fetch(url)
      return response
    } else {
      response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(postData)
      })
    }
  } catch(e) {
    response = {
      'body'   : '401/' + e,
      'status' : 400
    }
    return response
  } 
}

async function getReadStream (obj) {
  if (obj && typeof obj.getReader === 'function') {
    const reader = obj.getReader()
    const decoder = new TextDecoder('utf-8')
    let result = ''
    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        break
      }
      result += decoder.decode(value)
    }
    return result
  } else {
    return obj
  }
}

function logPrint(text) {
  if (!DEBUG) return
  console.log(text)
}

function isAdminUser () {
  const permissions = sessionStorage.getItem('permissions')
  logPrint(permissions)
  return (permissions !== null)
}

async function POSTResponse(response, self) {
  let statusCode
  let body
  try {
    //logPrint(response.statusCode)
    if (response.hasOwnProperty('message') &&
        response.message.includes('Invalid request body')) {
        // 不正アクセス(400)
        self.$router.replace({ path: '/AccessDenied' })
        return [400, "Invalid request body"]
    } else if(response.hasOwnProperty('errorMessage')) {
      if(response.errorMessage.includes('Task timed out')){
        statusCode = 510
        body = "510/Lambda Timeout."
      } else   {
        statusCode = 999
        body = response.errorMessage
      }
      return [statusCode, body]
    }
    statusCode = response.statusCode
    body = await JSON.parse(response.body)
  } catch {
    logPrint(response)
    self.$router.replace({ path: '/AccessDenied' })
    //statusCode = 510
    //body = "510/Lambda Timeout."
  }
  return [statusCode, body]
}

async function POSTRequestWithoutAuth(url, requestBody = {}){
  let response
  
  // requestBody内にidTokenパラメータを追加する
  //const userIdToken = sessionStorage.getItem('userIdToken')
  //const temp = {idToken: userIdToken}
  //requestBody = Object.assign({}, requestBody, temp)
  //logPrint(requestBody)
  // 
  try {
    response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody)
    })/*.then(responses => {
      responses.json()
    })*/
    // thenを利用すると、response.json()が同期取れない場合があるので、分けて awaitした。
    logPrint("--------------------------------------------------")
    logPrint("Request URL:" + url)
    logPrint("Request Body:\n")
    logPrint(requestBody)
    const responses = await response.json()
    logPrint("Response:\n")
    logPrint(responses)
    logPrint("--------------------------------------------------")

    //console.log(responses)
    return responses
  } catch(e) {
    logPrint(e)
  }
}

async function POSTRequest(url, requestBody = {}){
  let response
  // requestBody内にidTokenパラメータを追加する
  //const userIdToken = sessionStorage.getItem('userIdToken')
  //const temp = {idToken: userIdToken}
  //requestBody = Object.assign({}, requestBody, temp)
  //
  //logPrint(requestBody) 
  try {
    if (!VulnerabilityAssessment) {
      const userIdToken = sessionStorage.getItem('userIdToken')
      logPrint(userIdToken)
      response = await fetch(url, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userIdToken
        },
        body: JSON.stringify(requestBody)
      })
    } else {
      response = await fetch(url, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      })
    }
    logPrint("--------------------------------------------------")
    logPrint("Request URL:" + url)
    logPrint("Request Header:\n")
    logPrint(requestBody)
    const responses = await response.json()
    logPrint("Response:\n")
    logPrint(responses)
    logPrint("--------------------------------------------------")

    //console.log(responses)
    return responses
  } catch(e) {
    logPrint(e)
  }
}
export default {
  RELEASE,
  getErrorCodeFromResponceBody,
  logPrint,
  isAdminUser,
  getRequestResponse,
  getReadStream,
  POSTRequest,
  POSTResponse,
  checkMailAddress,
  POSTRequestWithoutAuth,
  UPDATE_TIMESTAMP,
  VulnerabilityAssessment,
  ModuleStatus
}
