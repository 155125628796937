<template>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="認証コードの確認中です。"
    title="認証コード検証中"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleParamError"
    message="認証コードは必ず入力してください。"
    title="認証コード未入力"
    btn1Text="OK"
    messageType="1"
    @close="CloseParamError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleErrorMessage"
    :message="ErrorMessage"
    :title="ErrorTitle"
    btn1Text="OK"
    messageType="2"
    @close="CloseErrorMessage"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度やり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleDeleteProgress"
    message="ユーザー情報の削除中です。"
    title="削除中"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleError1"
    :message="ErrorMessage"
    :title="ErrorTitle"
    btn1Text="OK"
    messageType="2"
    @close="closeErrorDialog1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleCompleted"
    message="削除処理が完了しました。<br><br>サインイン画面に戻ります。"
    title="削除完了"
    btn1Text="OK"
    messageType="0"
    @close="closeCompleted"
  ></ModalBox>
  <MenuPanel class="DeletePanel" showLogout="false" PageName="削除用認証コード確認" showUserEdit="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <p>認証コード: <LabelBox id="verificationCode" TypeName="text" name="password" oncontextmenu="return false" maxlength="10"></LabelBox></p>
      <div class="ButtonLayout">
        <ButtonBox name="ReturnButton" ButtonName="戻る" @click="ReturnLogin()"></ButtonBox>
        <ButtonBox name="LoginButton" ButtonName="削除実行" @click="SendCode()"></ButtonBox>
      </div>
      <p class="information">認証コードが届かない場合、「戻る」からやり直してください。</p>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'

export default {
  name: 'AuthView',
  components: {
    LabelBox,
    ButtonBox,
    ModalBox,
    ProgressBox,
    MenuPanel
  },
  data () {
    return {
      isVisibleError1: false,
      isVisibleCompleted: false,
      isVisibleDeleteProgress: false,
      isVisibleTimeout: false,
      isVisibleProgress1: false,
      isVisibleErrorMessage: false,
      isVisibleParamError: false,
      isVisibleTimeout: false,
      ErrorMessage: '',
      ErrorTitle: ''
    }
  },
  methods: {
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
      this.ReturnLogin()
    },
    CloseParamError () {
      this.isVisibleParamError = false
      this.ReturnLogin()
    },
    CloseErrorMessage (e) {
      this.isVisibleErrorMessage = false
      this.ReturnLogin()
    },
    async SendCode () {
      // 削除処理を開始
      this.isVisibleDeleteProgress = true

      // 認証コード送信を実行。AWS Cognitoの認証処理を実施する。
      const result = await this.checkVerificationCode()
      if (!result) {
        this.isVisibleDeleteProgress = false
        return
      }

      const ret = await this.deleteUserExecute()
      //const ret = true
      this.isVisibleDeleteProgress = false

      if (ret) {
        this.isVisibleCompleted = true
      } else {
        this.isVisibleError1 = true
      }
    },
    closeErrorDialog1 () {
      this.isVisibleError1 = false
      this.ReturnSiginIn()
    },
    closeCompleted () {
      this.$router.replace({ name: 'Login' })
    },
    async deleteUserExecute () {
      // Lambdaをたたいて処理する
      const userID = sessionStorage.getItem('DeleteUserID')
      
      // API Gatewayに渡すクエリパラメータ設定
      // ユーザー管理の削除機能と同一の機能なので、流用。
      // 削除処理実行
      // lambdaを呼び出す
      //const url = "https://w9t7dgvlak.execute-api.ap-northeast-1.amazonaws.com/alpha2/"
      const url = URLS.userEdit_deleteUser[Common.RELEASE]
      const requestBody = {
        userid: userID
      }
      
      // Lambdaをキックして結果を取得する
      const responseAll = await Common.POSTRequest(url, requestBody)
      const [status, body] = await Common.POSTResponse(responseAll, this)
      
      if (status === 200) {
        return true
      } else {
        const errCode = await Common.getErrorCodeFromResponceBody(body)
        switch (errCode) {
          case 501:
            const userID = sessionStorage.getItem('userid')
            this.ErrorTitle = "削除エラー"
            this.ErrorMessage = 'サインインID"'+ userID + '"は削除できません。<br>' + 
                                '電子請求書を受信可能なユーザーが最低1ユーザー必要です。'
            break

          case 502:
            this.ErrorTitle = "削除エラー"
            this.ErrorMessage = 'ユーザーの削除に失敗しました。<br>' +
                                'しばらく経ってからもう一度やり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            break

            // Lambda タイムアウト対応
          case 510:
            this.ErrorTitle = "サーバーエラー"
            this.ErrorMessage = '処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度「ユーザー削除実行」からやり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。'
            break

          default:
            this.ErrorTitle = "削除エラー"
            this.ErrorMessage = "ユーザーの削除に失敗しました。<br>" +
                                "しばらく経ってからもう一度やり直してください。<br><br>" +
                                "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                                "Message:" + body
            break
        }

        return false
      }
    },
    ReturnLogin () {
      this.$router.replace({ name: 'UserDelete' })
    },
    ReturnSiginIn () {
      this.$router.replace({ name: 'Login' })
    },
    async checkVerificationCode () {

      // 認証コード未入力確認
      const verificationCode = document.getElementById('verificationCode').value
      if (verificationCode === '') {
        this.isVisibleParamError = true
        return false
      }

      let result
      if (!Common.VulnerabilityAssessment) {
        result = await this.verifyMFA(verificationCode) // Lambdaによる認証コード確認処理(6/16更新予定なので蓋する)
      } else {
        result = true
      }
      return result
    },
    async verifyMFA (verificationCode) {
      //6/16用
      const url = URLS.auth_MFAVerification[Common.RELEASE]

      const mfaSession = sessionStorage.getItem('mfaSession')
      const userID = sessionStorage.getItem('mfaUserId')
      const requestBody = {
        userID: userID,
        answer: verificationCode,
        session: mfaSession
      }

      const response = await Common.POSTRequestWithoutAuth(url, requestBody)
      const [status, body] = await Common.POSTResponse(response, this)

      // サインイン後ステータスを確認し、処理を行う。
      if (status === 200) {
        sessionStorage.setItem('userAccessToken', body.AccessToken)
        sessionStorage.setItem('userIdToken', body.IdToken)
        sessionStorage.setItem('userRefreshToken', body.RefreshToken)
        sessionStorage.removeItem('mfaSession')
        sessionStorage.removeItem('mfaUserId')
        return true
      } else {
        this.isVisibleProgress1 = false
        //const errCode = await Common.getErrorCodeFromResponceBody(body)
        switch(status) {
          // 認証コード誤り
          case 501:
            this.ErrorTitle = '認証コードエラー'
            this.ErrorMessage = '認証コードに誤りがあります。<br>' +
                                'もう一度ユーザー削除実行画面からやり直してください'
            this.isVisibleErrorMessage = true
            break

          // 認証コード有効期限切れ
          case 502:
            this.ErrorTitle = '有効期限切れ'
            this.ErrorMessage = '認証コードの有効期限が切れています。<br>' +
                                'もう一度ユーザー削除実行画面からやり直してください'
            this.isVisibleErrorMessage = true
            break

            // Time Out
          case 510:
            this.isVisibleTimeout = true
            break
                
          default:
            this.ErrorTitle = '予期しないエラー'
            this.ErrorMessage = '認証コードの確認に失敗しました。<br>' +
                                'しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:'+ body
            this.isVisibleErrorMessage = true
            break
        }
        sessionStorage.removeItem('mfaSession')
        sessionStorage.removeItem('userAccessToken')
        sessionStorage.removeItem('userIdToken')
        sessionStorage.removeItem('userRefreshToken')
        sessionStorage.removeItem('mfaUserId')
        return false
      }
    }
  }
}
</script>

<style scoped>
  .DeletePanel {
    background-color: rgb(238, 127, 127);
  }

  p {
    color:black;
  }

  .information {
    font-size: 11px;
  }

  .ButtonLayout {
    text-align: center;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 350px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    color: #fff;
    text-align: center;
  }

 </style>
