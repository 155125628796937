// サインイン
import AWS from 'aws-sdk'
import common from './common'

// PoolID
// 開発：一般用, 開発：請求元用
// 本番：一般用, 本番：請求元用
const POOLID = [
  ['ap-northeast-1:72416c3c-e59a-49cf-b79f-706ebd44e41b', 'ap-northeast-1:e509b7c9-ee4c-4117-85be-c03058771cbc'],
  ['ap-northeast-1:e5aa3b2c-fd8b-45ac-8ac7-b3e5c6da7b53', 'ap-northeast-1:9f6002e9-3e62-4915-8db7-1efe1915bec0'],
  ['ap-northeast-1:90ab5f6a-7da0-4d7e-9eae-05328e4f38ca', 'ap-northeast-1:b84989ae-4c81-443a-8be1-2fc5ad4302dc']
]

const REGION = 'ap-northeast-1'

// ClientID
// 開発：一般用, 開発：請求元用
// 本番：一般用, 本番：請求元用
const CLIENTID = [
  ['hpt55fckjtjm1vm423c3piv2j','bop6v87drc4d2283oamv619qa'],
  ['1v9kr6s6l1727v61bs47kbn0a0', '72cr33h31qu4bthbi8vajo2p0g'],
  ['2dal6g0v309q4k6pjaie5jh0f0', '4hms0tf57glqvtqu675qbhldkm']
]

// パスワードの更新を行う
// パスワードに誤りがあった場合、idTokenが破棄されてしまうためサインアウト状態になってしまう。
// 何か対策が必要になると考える。
//
// パスワード変更処理
// result
//   0: 正常終了
//   1: 前パスワードに誤りがある
//   2: 新パスワードがポリシーにマッチしていない
//   3: 新パスワードの長さがポリシーに適していない
//  -1: その他エラー
async function CognitoChangePassword (beforePassword, password) {
  const userAccessToken = sessionStorage.getItem('userAccessToken')
  const params = {
    "AccessToken": userAccessToken,
    "PreviousPassword": beforePassword,
    "ProposedPassword": password
  }

  // 管理者か一般かで判断し、指定するプールを変更する
  let index = 0
  if (common.isAdminUser()) index = 1

  AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    ClientId: CLIENTID[common.RELEASE][index],
    IdentityPoolId: POOLID[common.RELEASE][index] // ユーザープールID
  })

  AWS.config.apiVersions = {
    cognitoidentityserviceprovider: '2016-04-18'
  }

  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
  try {
    const result = await cognitoidentityserviceprovider.changePassword(params).promise()
  } catch(e) {
    // エラーになると、idTokenの有効期限が切れる。
    // やばい。
    switch (e.code) {
      // 旧パスワードに間違いがある
      case 'NotAuthorizedException':
        common.logPrint(e)
        return [1, e]

      // パスワードポリシーにマッチしていない
      case 'InvalidPasswordException':
        common.logPrint(e)
        return [2, e]

      // パラメータ不足(前パスがない、ユーザーIDがない、後パスがない等)
      case 'InvalidParameterException':
        common.logPrint(e)
        return [3, e]
  
      default:
        common.logPrint(e)
        return [-1, e]
    }
  }
  return [0, '']
}

function checkSession (self) {
  if (!common.VulnerabilityAssessment) {
    if (common.isAdminUser()) {
      return CognitoGetSession_Admin(self)
    } else {
      return CheckSession_Client(self)
    }
  } else {
    return true
  }
}

// 請求元ユーザーのセッションタイムアウト検知
async function CognitoGetSession_Admin(self) {
  let ret = false;
  const userRefreshToken = sessionStorage.getItem('userRefreshToken');
  var params = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: CLIENTID[common.RELEASE][1], //CognitoアプリクライアントID
    AuthParameters: {
      REFRESH_TOKEN: userRefreshToken,
    },
  };

  AWS.config.region = 'ap-northeast-1'; // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID[common.RELEASE][1]
  });

  AWS.config.apiVersions = {
    cognitoidentityserviceprovider: '2016-04-18',
  };

  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

  try {
    const result = await cognitoidentityserviceprovider.initiateAuth(params).promise()
    sessionStorage.setItem('userAccessToken', result.AuthenticationResult.AccessToken);
    sessionStorage.setItem('userIdToken', result.AuthenticationResult.IdToken);
    ret=true;
  } catch(error) {
    sessionStorage.clear();
    self.$router.replace('SessionTimeOut');
  }
  return ret;
}

// 請求先ユーザーのセッションタイムアウト検知
function CheckSession_Client(self) {
  let ret = false;
  try{
    const userAccessToken = sessionStorage.getItem('userAccessToken')
    const payload = JSON.parse(atob(userAccessToken.split('.')[1]))

    let exp = (payload.exp) * 1000
    common.logPrint("SessionID:" + exp)
    const now = new Date().getTime()

    //セッションの有効期限が切れているか確認
    if (now > exp) {
      //ログイン画面かログアウト画面に移動処理
      // サインアウト、画面遷移(SessionTimeOut)
      sessionStorage.clear()
      self.$router.replace('/SessionTimeOut')
      common.logPrint("セッション期限超過によるデバッグコード")
      ret =  false
    } else {
      ret = true
    }
  }catch{
    common.logPrint("セッション情報の更新に失敗しました。お手数ですが一度ブラウザを閉じてから再度ログインをしてください。問題が何度も続く場合は管理者にご連絡ください。");
    sessionStorage.clear();
    self.$router.replace('/SessionTimeOut')
    ret =  false
  }
  return ret
}

async function CognitoSignIn (userId, password, admin = 0) {
  const params = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: CLIENTID[common.RELEASE][admin], // CognitoアプリクライアントID
    AuthParameters: {
      USERNAME: userId,
      PASSWORD: password
    }
  }

  AWS.config.region = REGION // リージョン

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID[common.RELEASE][admin] // ユーザープールID
  })

  AWS.config.apiVersions = {
    cognitoidentityserviceprovider: '2016-04-18'
  }

  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

  try {
    const result = await cognitoidentityserviceprovider.initiateAuth(params).promise()
    sessionStorage.setItem('userAccessToken', result.AuthenticationResult.AccessToken)
    sessionStorage.setItem('userIdToken', result.AuthenticationResult.IdToken)
    sessionStorage.setItem('userRefreshToken', result.AuthenticationResult.RefreshToken)
    common.logPrint(result)
    return true
  } catch (error) {
    common.logPrint(error)
    return false
  }
}

// サインアウト処理
function CognitoSignout () {
  try {
    const userAccessToken = sessionStorage.getItem('userAccessToken')
    // const userRefreshToken = sessionStorage.getItem('userRefreshToken');
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

    const params = {
      AccessToken: userAccessToken
    }

    cognitoidentityserviceprovider.globalSignOut(params, function (err, data) {
      if (err) common.logPrint(err, err.stack)
      else common.logPrint('Successfully sign out') // サインアウト処理
    })
  } finally {
    sessionStorage.removeItem('userAccessToken')
    sessionStorage.removeItem('userRefreshToken')
    sessionStorage.removeItem('userIdToken')
  }
}
// ユーザー取得処理。Cognitoの設計によって変更する必要あり
function CognitoGetUser () {
  const userAccessToken = sessionStorage.getItem('userAccessToken')
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
  const params = {
    AccessToken: userAccessToken
  }

  cognitoidentityserviceprovider.getUser(params, function (err, data) {
    if (err) common.logPrint(err, err.stack)
    else common.logPrint(data.Username)
  })
}

//パスワード変更処理。現仕様では使わない
/*
function setPassword (userid, chgpassword) {
  var params = {
    Password: chgpassword, 
    UserPoolId: 'ap-northeast-1_mpG1w7DWZ', 
    Username: userid, 
    Permanent: true
  }

  AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID //ユーザープールID
  })

  AWS.config.apiVersions = {
    cognitoidentityserviceprovider: '2016-04-18'
  }

  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
  
  cognitoidentityserviceprovider.adminSetUserPassword(params, function(err, data) {
    if (err) common.logPrint(err, err.stack) // an error occurred
    else     common.logPrint(data)           // successful response
  })
}
*/
//メールアドレス変更処理
async function changeEmailAttributes (mailaddress) {

  // 管理者か一般かで判断し、指定するプールを変更する
  let index = 0
  if (common.isAdminUser()) index = 1

  const mailAddress  = mailaddress
  const userAccessToken = sessionStorage.getItem('userAccessToken')
  //const userRefreshToken = sessionStorage.getItem('userRefreshToken');
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

  AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID[common.RELEASE][index] //ユーザープールID
  })

  var params = {
    AccessToken: userAccessToken , 
    UserAttributes: [ 
      {
        Name: 'email', 
        Value: mailAddress
      }
    ]
  }
  try {
    const result = await cognitoidentityserviceprovider.updateUserAttributes(params).promise()
    common.logPrint(result)
    return [true, ""]
  } catch (error) {
    common.logPrint(error)
    return [false, error]
  }
}

//メールアドレス変更時の認証コード入力
async function verifyEmailCode (veryficationcode) {
  // 管理者か一般かで判断し、指定するプールを変更する
  let index = 0
  if (common.isAdminUser()) index = 1

  const userAccessToken = sessionStorage.getItem('userAccessToken')
  //const userRefreshToken = sessionStorage.getItem('userRefreshToken');
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
  const emailVeryficationCode = veryficationcode

  AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID[common.RELEASE][index] //ユーザープールID
  })

  var params = {
    AccessToken: userAccessToken, 
    AttributeName: 'email', 
    Code: emailVeryficationCode 
  }

  try {
    const result = await cognitoidentityserviceprovider.verifyUserAttribute(params).promise()
    return [0, '']
  } catch (e) {
    switch (e.code) {
      // コードに誤りがある
      case 'CodeMismatchException':
        common.logPrint(e)
        return [1, e]

      // コードの有効期限が切れた
      case 'ExpiredCodeException':
        common.logPrint(e)
        return [2, e]
  
      default:
        common.logPrint(e)
        return [-1, e]
    }
  }
}


// 電話番号登録、変更処理
// 電話番号に対して、認証コードを送る
/*
function changeUserPhoneNumberAttributes(phonenumber){

  //const phoneNumber  ="+8109049139981";
  let tempPhoneNumber  = phonenumber
  let phoneNumber = "+81"+tempPhoneNumber

  const userAccessToken = sessionStorage.getItem('userAccessToken')
  //const userRefreshToken = sessionStorage.getItem('userRefreshToken');
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()

  AWS.config.region = REGION; // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID //ユーザープールID
  })

  var params = {
    AccessToken: userAccessToken , 
    UserAttributes: [
      {
        Name: 'phone_number', 
        Value: phoneNumber
      }
    ]
  }

  cognitoidentityserviceprovider.updateUserAttributes(params, function(err, data) {
    if (err) common.logPrint(err, err.stack) // an error occurred
    else     common.logPrint(data);          // successful response
  })
  
  return true
}

// 電話番号登録、変更時の認証コード入力
// SMS電話番号に対する認証コードの確認後、SMS電話番号の登録を行う
function verifySmsCode(smsveryficationcode){

  const userAccessToken = sessionStorage.getItem('userAccessToken')
  //const userRefreshToken = sessionStorage.getItem('userRefreshToken');
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
  const smsVeryficationCode = smsveryficationcode

  AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID, //ユーザープールID
  })

  var params = {
    AccessToken: userAccessToken, 
    AttributeName: 'phone_number',
    Code: smsVeryficationCode 
  }
  cognitoidentityserviceprovider.verifyUserAttribute(params, function(err, data) {
    if (err) common.logPrint(err, err.stack) // an error occurred
    else     common.logPrint(data)           // successful response
  })

  return true
}
*/

//パスワードリセット
async function forgotPassword(userid){
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({ region: REGION })
  const FPuserId = userid // Forget Password user id ?かな
  let index = 0
  const admin = await sessionStorage.getItem('passwordReset')
  if (admin=='1') index = 1

  //AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID[common.RELEASE][index] //ユーザープールID
  })

  const params = {
    ClientId: CLIENTID[common.RELEASE][index], 
    Username: FPuserId
  }

  try {
    const result = await cognitoidentityserviceprovider.forgotPassword(params).promise()
    common.logPrint(result)
    common.logPrint("Successfully reset password")
    return [true, ""]
  } catch (error) {
    common.logPrint(error)
    return [false, error]
  }
}

//パスワードリセット時認証コード
// エラー処理対応として、例外を検知し、何が間違っているのかを確認できるようにする。(6/7)
// 
async function confirmForgotPassword (userid, newpassword, veryficationcode) {
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({ region: REGION })
  const FPveryficationCode = veryficationcode
  const newPassword = newpassword
  //User名をもう一度入力。仕様を確認。
  const FPuserId = userid
  let index = 0
  const admin = await sessionStorage.getItem('passwordReset')

  if (admin=='1') index = 1
  //AWS.config.region = REGION // リージョン
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOLID[common.RELEASE][index] //ユーザープールID
  })

  var params = {
    ClientId: CLIENTID[common.RELEASE][index], 
    ConfirmationCode: FPveryficationCode, 
    Password: newPassword,
    Username: FPuserId
  }

  try {
    const result = await cognitoidentityserviceprovider.confirmForgotPassword(params).promise()
    common.logPrint(result)
    common.logPrint("Successfully set new password")
    return [0, '']
  } catch (e) {
    switch (e.code) {
      // 旧パスワードに間違いがある
      case 'NotAuthorizedException':
        common.logPrint(e)
        return [1, e]

      // パスワードポリシーにマッチしていない
      case 'InvalidPasswordException':
        common.logPrint(e)
        return [2, e]

      // パラメータ不足(前パスがない、ユーザーIDがない、後パスがない等)
      case 'InvalidParameterException':
        common.logPrint(e)
        return [3, e]

      // 認証コード間違え
      case 'CodeMismatchException':
        common.logPrint(e)
        return [4, e]

      // 認証コード期限切れ(ここに到達することはありえないと考える)
      case 'ExpiredCodeException':
        common.logPrint(e)
        return [5, e]

      // それ以外のエラー
      default:
        common.logPrint(e)
        return [-1, e]
    }
  }
}

export default {
  CognitoSignIn,
  CognitoSignout,
  CognitoGetUser,
  //setPassword,
  changeEmailAttributes,
  verifyEmailCode,
  // changeUserPhoneNumberAttributes,
  // verifySmsCode,
  forgotPassword,
  confirmForgotPassword,
  CognitoChangePassword,
  checkSession
}
