<template>
  <teleport to="body">
    <div class="CP006_modal" id="CP006_modal" v-show="isVisible"></div>
    <div class="CP006_modal_content" v-show="isVisible">
      <div class="CP006_TitleBar">{{ title }}</div>
      <div class="CP006_Message" v-html="showMessage"></div>
    </div>
  </teleport>
</template>

<script>
import Common from '../modules/common.js'
const componentID = 'CP006'

export default {
  name: 'ProgressBox',
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    message: String,
    title: String
  },
  // 動的HTML設定を行うため、computedを使用する。dataでは反応しない。
  computed: {
    showMessage () {
      return this.message
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .CP006_modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .CP006_modal_content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #000000;
    width: auto;
    height: auto;
    padding: 2px;
    font-size: 12px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .CP006_TitleBar{
    background-color: #001aff;
    width: 100%;
    color:white;
    font-size: 14px;
    padding: 1px 1px;
    margin: 0px;
    border: 0px;
  }
  .CP006_Message {
    width: 100%;
    padding: 4px;
    margin: 4px;
    text-align: center;
  }

</style>
