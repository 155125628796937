<template>
  <button :name="name" type="button">{{ ButtonName }}</button>
</template>

<script>
import Common from '../modules/common.js'
const componentID = 'CP001'

export default {
  name: 'ButtonBox',
  props: {
    ButtonName: String,
    name: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button {
  height:30px;
  font-size: 11px;
  margin: 0px;
  padding-left:20px;
  padding-right:20px;
  text-align: center;
  background-color: #595959;
  color: rgb(255, 255, 255);
  margin-right: 5px;
  border: solid 1px;
  border-radius: 3px;
}

button:hover {
  background-color: #242424;
  color: rgb(253, 255, 153);
  box-shadow: 0px 0px 5px 2px #eaff9f;
}

</style>
