<template>
  <input v-if="readonly" readonly class="readonly" :type="TypeName" :name="name" :oncontextmenu="oncontextmenu" />
  <input v-else :autocomplete="autocomplete" :type="TypeName" :name="name" :oncontextmenu="oncontextmenu" />
</template>

<script >
// :onpaste="onPaste" 
import Common from '../modules/common.js'
const componentID = 'CP003'
export default {
  name: 'LabelBox',
  props: {
    TypeName: { type: [String], default: 'text' },
    name: String,
    //onPaste: { type: [String], default: 'return true' },
    oncontextmenu: { type: [String], default: 'return true' },
    readonly: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'off'}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  padding: 3px 7px;
  border: 2px solid rgb(71, 71, 71);
}

input:focus {
  padding: 3px 7px;
  border: 2px solid rgb(7, 255, 152);
  background-color: rgb(183, 228, 255);
  box-shadow: 0px 0px 5px 0px #7b6dff;
}

input:invalid {
  border: 2px solid rgb(255, 7, 7);
  background-color: rgb(255, 207, 207);
  box-shadow: 0px 0px 5px 0px #ff6d6d;
}
.readonly {
  background-color: rgb(180, 180, 180);
  color: rgb(85, 85, 85);
}

.readonly:focus {
  background-color: rgb(180, 180, 180);
  color: rgb(85, 85, 85);
}
</style>
