<template>
  <ModalBox
    :isVisible="isVisibleLimitOver"
    message="検索結果が多すぎるため上位1000件を表示します。<br>必要な情報が見つからない場合、検索条件を絞り込んで再度検索を行ってください。"
    title="検索結果過多"
    btn1Text="OK"
    messageType="0"
    @close="closeDialogLimitOver"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleCountZero"
    message="検索条件に一致する情報はありませんでした。"
    title="検索結果"
    btn1Text="OK"
    messageType="0"
    @close="closeCountZero"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleDeleteCompleted"
    message="帳票ファイルの削除が完了しました。"
    title="削除完了"
    btn1Text="OK"
    messageType="0"
    @close="closeDialogDeleteCompleted"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleDeleteConfirm1"
    :message="DeleteConfirmMessage1"
    title="削除実行確認"
    btn1Text="削除処理継続"
    btn2Text="キャンセル"
    btn1Color="#FF0000"
    btn1TextColor="#FFFFFF"
    messageType="0"
    @close="closeDialogDeleteConfirm1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleDeleteConfirm2"
    :message="DeleteConfirmMessage2"
    title="削除実行最終確認"
    btn2Text="削除実行"
    btn1Text="キャンセル"
    btn2Color="#FF0000"
    btn2TextColor="#FFFFFF"
    messageType="0"
    @close="closeDialogDeleteConfirm2"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度やり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleMessage1"
    message="請求書を選択してから実行してください。"
    title="請求書未選択"
    btn1Text="OK"
    messageType="1"
    @close="closeDialog1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleGetListError"
    :message="GetListErrorMsg"
    title="請求書一覧取得エラー"
    btn1Text="OK"
    messageType="2"
    @close="closeGetListErrotDialog"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleDeleteError"
    :message="DeleteErrorMsg"
    title="請求書削除エラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDeleteErrorDialog"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleGetError"
    :message="GetPDFFileErrorMessage"
    title="請求書取得エラー"
    btn1Text="OK"
    messageType="2"
    @close="closeGetErrotDialog"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="請求書の検索中です。"
    title="検索中"
  ></ProgressBox>
  <ProgressBox
    :isVisible="isVisibleDeleteProgress"
    message="請求書の削除中です。"
    title="削除中"
  ></ProgressBox>
  <ProgressBox
    :isVisible="isVisibleProgress2"
    message="請求書のダウンロード中です。"
    title="表示中"
  ></ProgressBox>
  <ProgressBox
    :isVisible="isVisibleProgress3"
    message="検索画面の表示中です。"
    title="表示中"
  ></ProgressBox>
  <MenuPanel v-if="isDeletable" class="DeletePanel" PageName="請求先請求書削除" :showMessage="!AdminFlag"></MenuPanel>
  <MenuPanel v-else PageName="請求先請求書一覧" :showMessage="!AdminFlag"></MenuPanel>
  <div class="page">
    <div v-if="AdminFlag" class="searchPanel">
      <table>
        <tr>
          <td class="paramHead">
            請求年月:
          </td>
          <td>
            <SelectBox id="requestYear" name="searchRequestYear" :Datas="RYears"></SelectBox>年
            <SelectBox id="requestMonth" name="searchRequestMonth" :Datas="Months"></SelectBox>月
          </td>      
          <td class="paramHead">
            請求先コード:
          </td>
          <td>
            <LabelBox id="clientCode" TypeName="text" name="searchClientCode" maxlength="8"></LabelBox>
          </td>
        </tr>
        <tr>
          <td class="paramHead">
            請求先企業名:
          </td>
          <td>
            <LabelBox id="clientName" TypeName="text" name="searchClientName" maxlength="100"></LabelBox>
          </td>
          <td class="paramHead">
            作成年月:
          </td>
          <td>
            <SelectBox id="makeYear" name="searchRequestYear" :Datas="Years"></SelectBox>年
            <SelectBox id="makeMonth" name="searchRequestMonth" :Datas="Months"></SelectBox>月
          </td>
        </tr>
        <tr>
          <td class="paramHead">
            配信種別:
          </td>
          <td>
            <SelectBox id="PDFType" name="searchPDFType" :Datas="PDFType"></SelectBox>
          </td>
          <td class="paramHead">
            請求書No.:
          </td>
          <td>
            <LabelBox id="invoiceNo" TypeName="text" name="searchClientCode" maxlength="10"></LabelBox>
          </td>
        </tr>
        <tr>        
          <td class="paramHead">
            ファイル名:
          </td>
          <td>
            <LabelBox id="fileName" TypeName="text" name="searchFileName" maxlength="255"></LabelBox>
          </td>
          <td class="paramHead">
            ダウンロード状況:
          </td>
          <td>
            <SelectBox id="dlStatus" name="searchDlStatus" :Datas="dlStatus"></SelectBox>
          </td>
        </tr>
        <tr>  
          <td class="paramHead">
            最終ダウンロードユーザー:
          </td>
          <td>
            <LabelBox id="dluser" TypeName="text" name="searchDluser" maxlength="50"></LabelBox>
          </td>
          <td class="paramHead">

          </td>
          <td>

          </td>
        </tr>
        
        <!---
        <tr>
          <td class="paramHead">
            部課名:
          </td>
          <td>
            <LabelBox id="departmentName" TypeName="text" name="searchdepartmentName"></LabelBox>
          </td>
          <td class="paramHead">
          </td>
          <td>
          </td>
        </tr>
        -->
        <!---
        <tr>
          <td class="paramHead">
            最終閲覧者:
          </td>
          <td>
            <LabelBox TypeName="text" name="searchPerson"></LabelBox>
          </td>
          <td class="paramHead">
            最終閲覧ユーザーID:
          </td>
          <td>
            <LabelBox TypeName="text" name="searchPersonID"></LabelBox>
          </td>
        </tr>
        -->
      </table>
      <div class="searchButtonLayout">
        <ButtonBox class="searchBtn" name="SearchButton" ButtonName="検索" @click="execSearch()"></ButtonBox>
      </div>
    </div>
    <div class="dataArea">
      <DataTable ref="DataArea" id="DataArea" name="TestTable" :beforeContent="beforeContent" :dataType="dataType" :dataAlign="dataAlign" :colSize="colSize" :Headers="Headers" :Datas="showDatas"></DataTable>
    </div>
    <div class="ButtonControl">
      <table>
        <tr>
          <td v-if="AdminFlag" class="leftButton">
            <ButtonBox name="return" ButtonName="戻る" @click="returnMenu()"></ButtonBox>
          </td>
          <td class="rightButton">
            <ButtonBox name="showPDF" ButtonName="請求書を表示" @click="showPDF()"></ButtonBox>
          </td>
          <td v-if="isDeletable" class="rightButton">
            <ButtonBox class="deletePDF" name="deletePDF" ButtonName="請求書の削除" @click="deletePDF()"></ButtonBox>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ButtonBox from '../components/Button.vue'
import DataTable from '../components/TableWithPagerAndSorter.vue'
import SelectBox from '../components/SelectBox.vue'
import LabelBox from '../components/LabelBox.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ProgressBox from '../components/ProgressBox.vue'
import ModalBox from '../components/ModalBox.vue'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'
import CommonCognito from '../modules/commonCognito.js'
import common from '../modules/common.js'

const GRD_ADMIN_INVOICENO = 6
const GRD_ADMIN_CLIENTCODE = 2
const GRD_ADMIN_CLIENTNAME = 3
const GRD_USER_INVOICENO = 3
const DATA_FILENAME = 7
const DATA_REPORTTYPE = 12
const DATA_CLIENTCODE = 1
const DATA_INVOICENO = 6
      
export default {
  name: 'ReportList',
  components: {
    LabelBox,
    ButtonBox,
    DataTable,
    SelectBox,
    MenuPanel,
    ModalBox,
    ProgressBox
  },

  // 20221102_C0000001_手巣都株式会社_請求書_0123456789-001.pdf
  data () {
    const colSizeData = []
    const colType = ['String', 'String', 'String', 'String', 'String', 'String', 'String', 'String', 'Number', 'String', 'String', 'String', 'String']
    const colAlign = ['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'right', 'left', 'left', 'left', 'left']
    const beforeContent = ['', '', '', '', '', '', '', '', '&yen;', '', '', '', '']

    if (Common.isAdminUser()) {
      // 管理者の場合
      colSizeData.push('70px')     // 1:請求年月
      colSizeData.push('90px')     // 2:請求先コード
      colSizeData.push('150px')    // 3:請求先企業名
      colSizeData.push('Hidden')   // :部課名(隠してます)
      colSizeData.push('60px')     // 4:作成日
      colSizeData.push('80px')     // 5:配信種別
      colSizeData.push('110px')    // 6:請求書No.
      colSizeData.push('250px')    // 7:ファイル名
      colSizeData.push('90px')     // 8:請求金額合計
      colSizeData.push('110px')    // 9:ダウンロード状況
      colSizeData.push('135px')    // 10:最終ダウンロード日時
      colSizeData.push('165px')    // 11:最終ダウンロードユーザーID
      colSizeData.push('Hidden')   // :配信種別(コード)
    } else {
      // 一般の場合
      colSizeData.push('70px')     // 1:請求年月
      colSizeData.push('Hidden')   // 請求先コード
      colSizeData.push('Hidden')   // 請求先企業名
      colSizeData.push('Hidden')   // 部課名(隠してます)
      colSizeData.push('60px')     // 2:作成日
      colSizeData.push('Hidden')   // 配信種別
      colSizeData.push('110px')    // 3:請求書No.
      colSizeData.push('250px')    // 4:ファイル名
      colSizeData.push('90px')     // 5:請求金額合計
      colSizeData.push('110px')    // 6:ダウンロード状況
      colSizeData.push('135px')    // 7:最終ダウンロード日時
      colSizeData.push('165px')    // 8:最終ダウンロードユーザーID
      colSizeData.push('Hidden')   // 配信種別(コード)
    }

    return {
      RYears: [],
      Years: [],
      Months: ['','01','02','03','04','05','06','07','08','09','10','11','12'],
      // RequestMonths: [],
      // ここはなんかデータベース的なものから取得するようにすればいいかも
      Headers: ['請求年月', '請求先コード', '請求先企業名', '部課名', '作成日', '配信種別', '請求書No.', 'ファイル名', '請求金額合計', 'ダウンロード状況', '最終ダウンロード日時', '最終ダウンロードユーザー', 'PDF種別'],
      colSize: colSizeData,
      dataAlign: colAlign,
      dataType: colType,
      beforeContent: beforeContent,
      dlStatus: ['', '未', 'ダウンロード済'],
      PDFType: ['', 'Web配信', '一括郵送', '個別郵送'],   // 配信種別が増えた場合ここも対応すること
      PDFTypeNumber: ['', '10', '20', '30'],            // 配信種別が増えた場合ここも対応すること
      AdminFlag: Common.isAdminUser(),
      showDatas: [],
      isVisibleLimitOver: false,
      isVisibleProgress1: false,
      isVisibleMessage1: false,
      isVisibleProgress2: false,
      isVisibleProgress3: false,
      isVisibleGetError: false,
      GetPDFFileErrorMessage: '',
      isVisibleGetListError: false,
      GetListErrorMsg: '',
      isVisibleTimeout: false,
      isDeletable: false,
      isVisibleDeleteConfirm1: false,
      isVisibleDeleteConfirm2: false,
      DeleteConfirmMessage1: '',
      DeleteConfirmMessage2: '',
      isVisibleDeleteError: false,
      DeleteErrorMsg: '',
      isVisibleDeleteCompleted: false,
      isVisibleDeleteProgress: false,
      isVisibleCountZero: false
    }
  },
  async mounted () {
    if (!this.AdminFlag) {
      const [status, body] = await this.getDataFromRDS()
      if (status === 510) {
        this.isVisibleTimeout = true
        return
      }
      
      if (status!==200) {
        this.isVisibleGetListError = true
        this.GetListErrorMsg = "請求書一覧の取得に失敗しました。<br>" +
                               "しばらく待ってからもう一度やり直してください。<br><br>" +
                               "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                               "Message:" + body
        return
      }

      this.showDatas = body
    } else {
      // Delete可能かどうかの判断
      const delflg = sessionStorage.getItem('DeleteReport')
      if (delflg === "1") this.isDeletable = true

      const [status, body] = await this.getParamsFromRDS()

      if (status === 510) {
        this.isVisibleTimeout = true
        return
      }

      // 作成年のドロップダウン生成
      this.Years.push('')
      const years = body.MakeYears
      years.forEach((data) => {
        this.Years.push(data)
      })
      this.RYears.push('')
      const ryear = body.RequestYears
      ryear.forEach((data) => {
        this.RYears.push(data)
      })
    }
  },
  computed: {
  },
  methods: {
    closeDialogLimitOver () {
      this.isVisibleLimitOver = false
    },
    closeCountZero () {
      this.isVisibleCountZero = false
    },
    closeDeleteErrorDialog () {
      this.isVisibleDeleteError = false
    },
    closeDialogDeleteCompleted () {
      this.isVisibleDeleteCompleted = false
    },
    closeGetListErrotDialog () {
      this.isVisibleGetListError = false
    },
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
    },
    closeGetErrotDialog () {
      this.isVisibleGetError = false
    },
    async getParamsFromRDS () {
      // 検索画面の準備をしています。(Progress表示)
      this.isVisibleProgress3 = true
      //const url = 'https://c15utb8ogc.execute-api.ap-northeast-1.amazonaws.com/alpha2/'
      const url = URLS.reportList_getParams[Common.RELEASE]
      const response = await Common.POSTRequest(url)
      const result= await Common.POSTResponse(response, this)
      this.isVisibleProgress3 = false

      return result
    },
    async execSearch (showProgress = true) {
      // セッションチェック
      if(!CommonCognito.checkSession(this)) return

      const [status, body] = await this.getDataFromRDS(showProgress)
      if (status===510) {
        this.isVisibleTimeout = true
        return
      }

      // 1000件を超えた場合、警告を表示する
      if (status===201) {
        this.isVisibleLimitOver = true
        this.showDatas = body
        return
      }

      if (status!==200) {
        this.isVisibleGetListError = true
        this.GetListErrorMsg = "請求書一覧の取得に失敗しました。<br>" +
                               "しばらく待ってからもう一度やり直してください。<br><br>" +
                               "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                               "Message:" + body
        return
      }

      this.showDatas = body
      //Common.logPrint(this.showDatas)
      if (this.showDatas.length === 0) {
        this.isVisibleCountZero = true
      }
    },
    closeDialog1 (e) {
      this.isVisibleMessage1 = false
    },
    returnMenu () {
      // セッションチェック
      if(!CommonCognito.checkSession(this)) return
      sessionStorage.removeItem('DeleteReport')
      this.$router.replace({ name: 'MaintenanceMenu' })
    },
    async getDataFromRDS (showProgress = true) {
      // 請求書を検索しています。(Progress表示)
      if (showProgress) this.isVisibleProgress1 = true
      // 検索パラメータを取得(なお、adminじゃない場合は自身のクライアントコードをキーとする)
      let clientcode = ''
      let invoiceno = ''
      let makeyear = ''
      let makemonth = ''
      let makeyearmonth = ''
      let requestyear = ''
      let requestmonth = ''
      let filename = ''
      let clientname = ''
      let departmentname = ''
      let dluser = ''
      let dlstatus = ''
      let reporttype = ''
      let admin = '0'
      Common.logPrint(this.AdminFlag)
      if (this.AdminFlag) {
        // 権限ありの人は、クエリーパラメーターを制御盤から持ってくる
        clientcode = document.getElementById('clientCode').value
        invoiceno = document.getElementById('invoiceNo').value
        filename = document.getElementById('fileName').value
        clientname = document.getElementById('clientName').value
        dluser = document.getElementById('dluser').value
        admin = '1'

        const makeyearobj = document.getElementById('makeYear')
        let idx1 = makeyearobj.selectedIndex
        if (idx1 !== -1) makeyear = makeyearobj.options[idx1].text

        const requestyearobj = document.getElementById('requestYear')
        let idx2 = requestyearobj.selectedIndex
        if (idx2 !== -1) requestyear = requestyearobj.options[idx2].text

        const dlStatus = document.getElementById('dlStatus')
        let idx3 = dlStatus.selectedIndex
        if (idx3 !== -1) dlstatus = dlStatus.options[idx3].text

        const makemonthobj = document.getElementById('makeMonth')
        let idx4 = makemonthobj.selectedIndex
        if (idx4 !== -1) makemonth = makemonthobj.options[idx4].text

        const requestmonthobj = document.getElementById('requestMonth')
        let idx5 = requestmonthobj.selectedIndex
        if (idx5 !== -1) requestmonth = requestmonthobj.options[idx5].text

        if(makemonth != '' || makeyear != ''){
          if (makeyear !='') {
            makeyearmonth = makeyear 
          } else {
            makeyearmonth = '%'
          }

          makeyearmonth += "/"

          if(makemonth != '') {
            makeyearmonth += makemonth
          } else {
            makeyearmonth += '%'
          }
        }

        // PDF種別は、数値(コード)に置き換えて渡す
        // ※LambdaのInputに変更を入れないため
        const pdftypeobj = document.getElementById('PDFType')
        let idx6 = pdftypeobj.selectedIndex
        if (idx6 !== -1) reporttype = this.PDFTypeNumber[idx6]
      } else {
        // 請求先ユーザーの場合、サインイン時にセットした情報を元に検索を行う
        clientcode = sessionStorage.getItem('userClient')
        reporttype = '10'
      }
      let url
      if (!common.isAdminUser()) {
        url = URLS.reportList_getInvoiceList[Common.RELEASE]
      } else {
        url = URLS.reportList_getInvoiceList_admin[Common.RELEASE]
      }
      
      Common.logPrint(dlstatus)
      const requestBody = {
        clientcode: clientcode,
        clientname: clientname,
        invoiceno: invoiceno,
        makeyear: makeyearmonth,
        requestyear: requestyear,
        requestmonth: requestmonth,
        filename: filename,
        dluser: dluser,
        dlstatus: dlstatus,
        departmentname: departmentname,
        reporttype: reporttype,
        admin: admin
      }
      const response = await Common.POSTRequest(url, requestBody)
      const result = await Common.POSTResponse(response, this)
      //console.log(this.$refs.DataArea)
      
      this.$refs.DataArea.clearSelect()

      // 請求書を検索しています。(Progress非表示)
      if (showProgress) this.isVisibleProgress1 = false
      return result
    },
    async closeDialogDeleteConfirm2 (e) {
      this.isVisibleDeleteConfirm2 = false
      if (e === '0') {
        return
      }

      const checkedIndex = this.$refs.DataArea.getSelectIndex()
      
      const url = URLS.reportList_deleteFile[Common.RELEASE]
      const userid = sessionStorage.getItem('userid')
      const mailaddress = sessionStorage.getItem('mailaddress')
      const clientcode = this.$refs.DataArea.getCellData(GRD_ADMIN_CLIENTCODE, checkedIndex)
      const invoiceno = this.$refs.DataArea.getCellData(GRD_ADMIN_INVOICENO, checkedIndex)

      // 現在選択している行から配列のINDEXを取得する
      const selectedRow = this.showDatas.findIndex(row => {
        return (row[DATA_CLIENTCODE] === clientcode && row[DATA_INVOICENO] === invoiceno)
      })

      const filename = this.showDatas[selectedRow][DATA_FILENAME]
      const reporttype = this.showDatas[selectedRow][DATA_REPORTTYPE]
      const requestBody = {
        clientcode:   clientcode,
        invoiceno:    invoiceno,
        filename:     filename,
        reporttype:   reporttype,
        userid:       userid,
        mailaddress:  mailaddress
      }

      this.isVisibleDeleteProgress = true
      const response = await Common.POSTRequest(url, requestBody)
      const [status, body] = await Common.POSTResponse(response, this)
      this.isVisibleDeleteProgress = false


      // エラーが発生した場合、以降の処理は実施しない。
      if (status !== 200) {
        const errcode = await Common.getErrorCodeFromResponceBody(body)
        switch (errcode) {
          // Lambda timeout
          case 510:
            this.isVisibleTimeout
            return
          
          //
          default:
            this.DeleteErrorMsg = "請求書削除処理でエラーが発生しました。<br>" + 
                                  "時間を置いて再度実行してください。<br><br>" + 
                                  "問題が解決しない場合は、管理者に問い合わせてください。<br>" +
                                  "Message: " + body
            break
        }
        this.isVisibleDeleteError = true
        return
      } else {
        this.$refs.DataArea.clearSelect()
        await this.execSearch(false)
        this.isVisibleDeleteCompleted = true
      }
    },
    closeDialogDeleteConfirm1 (e) {
      this.isVisibleDeleteConfirm1 = false
      if (e === '1') {
        return
      }

      this.DeleteConfirmMessage2 = '削除した請求書は閲覧することができなくなります。<br>' +
                                   '本当に削除しますか？'
      this.isVisibleDeleteConfirm2 = true
    },
    deletePDF () {
      // セッションチェック
      if(!CommonCognito.checkSession(this)) return

      const checkedIndex = this.$refs.DataArea.getSelectIndex()

      // チェックが入っていない場合は、何もせずに終了する
      if (checkedIndex === -1) {
        // 表示したい請求書を選択してください。(Message)
        this.isVisibleMessage1 = true
        return
      }

      const invoiceno = this.$refs.DataArea.getCellData(GRD_ADMIN_INVOICENO, checkedIndex)
      const clientcode = this.$refs.DataArea.getCellData(GRD_ADMIN_CLIENTCODE, checkedIndex)
      const clientname = this.$refs.DataArea.getCellData(GRD_ADMIN_CLIENTNAME, checkedIndex)

      this.DeleteConfirmMessage1 = '選択した請求書を削除しますか？<br>' +
                                    '請求書情報<br>' +
                                    '・請求書No."'+ invoiceno + '"<br>' + 
                                    '・請求先コード"'+ clientcode +'"<br>' +
                                    '・請求先企業名"'+ clientname +'"<br>' +
                                    '削除をしない場合は「キャンセル」を押してください。'
      this.isVisibleDeleteConfirm1 = true
    },
    async showPDF () {
      // セッションチェック
      if(!CommonCognito.checkSession(this)) return

      let clientcode = ''
      const userid = sessionStorage.getItem('userid')
      let invoiceno = ''
      let admin = ''

      const checkedIndex = this.$refs.DataArea.getSelectIndex()

      // チェックが入っていない場合は、何もせずに終了する
      if (checkedIndex === -1) {
        // 表示したい請求書を選択してください。(Message)
        this.isVisibleMessage1 = true
        return
      }

      if (this.AdminFlag) {
        clientcode = this.$refs.DataArea.getCellData(GRD_ADMIN_CLIENTCODE, checkedIndex)
        invoiceno = this.$refs.DataArea.getCellData(GRD_ADMIN_INVOICENO, checkedIndex)
        admin = '1'
      } else {
        clientcode = sessionStorage.getItem('userClient')
        invoiceno = this.$refs.DataArea.getCellData(GRD_USER_INVOICENO, checkedIndex)
        admin = '0'
      }

      // 現在選択しているユーザーIDから配列のINDEXを取得する
      const selectedRow = this.showDatas.findIndex(row => {
        return (row[DATA_CLIENTCODE] === clientcode && row[DATA_INVOICENO] === invoiceno)
      })

      const filename = this.showDatas[selectedRow][DATA_FILENAME]
      const reporttype = this.showDatas[selectedRow][DATA_REPORTTYPE]

      // 請求書を準備しています。(Progress表示)
      this.isVisibleProgress2 = true

      // S3から、ファイル名をキーにしてファイル実体をダウンロードする(Base64)
      //const url = 'https://4o6umjas09.execute-api.ap-northeast-1.amazonaws.com/alpha2/'
      let url
      if (!common.isAdminUser()) {
        url = URLS.reportList_getFileLink[Common.RELEASE]
      } else {
        url = URLS.reportList_getFileLink_admin[Common.RELEASE]
      }
      const requestHeader = {
        invoiceno: invoiceno,
        clientcode: clientcode,
        filename: filename,
        userid: userid,
        admin: admin,
        reporttype: reporttype
      }
      const response = await Common.POSTRequest(url, requestHeader)
      const status = response.statusCode
      const body = response.body

      // Timeout
      if (status === 510) {
        //Common.logPrint("ファイル名取得処理でタイムアウト")
        this.isVisibleProgress2 = false
        this.isVisibleTimeout = true
        return
      }
      // エラー処理
      if (status !== 200) {
        this.isVisibleProgress2 = false
        this.GetPDFFileErrorMessage = '請求書のダウンロードに失敗しました。<br>' + 
                                      'しばらく待ってからもう一度やり直してください。<br><br>' + 
                                      '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                      'Message:' + body
        this.isVisibleGetError = true
        return
      }
      const bodylink = response.body
      
      // ファイルのURLを取得
      //const bodyData = await Common.getReadStream(body)
      //const filebody = await JSON.parse(bodyData)
      Common.logPrint(bodylink)
      
      //
      // 通常のリンクを用いた場合 ------- Start
      // これを用いると、CORSのエラーは出ないが、ファイル形式依存してしまうっぽい
      //
      const link = document.createElement('a')
      link.href = bodylink// PDFファイルのURL
      //link.target = '_blank'
      link.download = filename
      //link.innerText = 'PDFを表示' // リンクに表示するテキスト
      
      // bodyにリンクを追加してクリックすることで表示する
      //document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(link.href)
      this.isVisibleProgress2 = false
      
      // クライアントユーザーの場合、請求書ダウンロード後、一覧の更新を行うようにする
      if (!this.AdminFlag) {
        const [status, body] = await this.getDataFromRDS()

        // Lambda Timeout発生
        if (status === 510) {
          this.isVisibleTimeout = true
          return
        }

        this.showDatas = body
        this.$refs.DataArea.clearSelect()
      }
      // PDF表示画面を表示
      // this.$router.replace({ name: 'ViewPDF' })
    },
    downloadPDF (data) {
      const binaryData = window.atob(data)
      const blob = new Blob([new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i))], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      window.open(pdfUrl)
    }
  }
}
</script>

<style scoped>
  .paramHead{
    padding: 0px 20px;
  }

  #revision{
    width: 60px;
  }

  .dataArea{
    display: block;
  }

  table {
    border-collapse: collapse;
  }
  .page {
    margin: 0px;
  }

  .searchPanel {
    padding-top: 10px;
    font-size: 12px;
    text-align: left;
    width: 804px;
    line-height: 2.5;
    background-color: rgb(230, 230, 230);
    display: block;
  }

  .ButtonControl {
    text-align: left;
    display: block;
    width: 800px;
  }

  td, tr {
    padding-left: 0px;
    margin: 0px;
  }

  .searchButtonLayout{
    text-align: right;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .searchBtn {
    margin-right: auto;
    margin-left : 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .rightButton {
    text-align: right;
  }

  .leftButton {
    text-align: left;
  }

  .deletePDF{
    background-color: red;
    color: white;
  }
  .deletePDF:hover{
    background-color: rgb(117, 45, 45);
    color: white;
  }

  .DeletePanel {
    background-color: rgb(238, 127, 127);
  }
  
  #DataArea {
    min-width: 1600px;
  }
</style>
