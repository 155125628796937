<template>
  <ModalBox
    :isVisible="isVisibleConfirm1"
    message="サインインIDを削除しますか？<br>この操作を行うと、請求書の確認が行えなくなります。<br><br>削除をしない場合は「キャンセル」を押してください。"
    title="削除実行確認"
    btn1Text="削除継続"
    btn2Text="キャンセル"
    btn1Color="red"
    btn1TextColor="white"
    messageType="0"
    @close="closeConfirmDialog1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleConfirm2"
    message="請求書はダウンロード済みですか？<br>サインインIDを削除すると、請求書の確認が行えなくなります。<br><br>サインインIDを削除しますか？"
    title="削除実行再確認"
    btn2Color="red"
    btn2TextColor="white"
    btn2Text="削除継続"
    btn1Text="キャンセル"
    messageType="0"
    @close="closeConfirmDialog2"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleConfirm3"
    message="サインインIDを削除しますか？<br>この操作を行うと元に戻すことができません。<br>「削除継続」を押した場合、ユーザー削除を行うため一度サインアウトを行います。<br>削除をしない場合は、「キャンセル」を押してください"
    title="削除実行最終確認"
    btn1Text="削除継続"
    btn1Color="red"
    btn1TextColor="white"
    btn2Text="キャンセル"
    messageType="0"
    @close="closeConfirmDialog3"
  ></ModalBox>
  <MenuPanel PageName="サインインユーザー情報" showUserEdit="false" showLogout="true"></MenuPanel>
  <div class="page">
    <div class="home">
      <table>
        <tr>
          <td>
            <p>ユーザー名:</p>
          </td>
          <td>
            <p class="showParameters">{{ UserName }}</p>
          </td>
          <td>
            <ButtonBox name="ChangeUserName" ButtonName="変更" @click="changeUserName()"></ButtonBox>
          </td>
        </tr>
        <!--
        <tr v-if="!Admin" >
          <td>
            <p>SMS用電話番号:</p>
          </td>
          <td>
            <p class="showParameters">{{ PhoneNumber }}</p>
          </td>
          <td>
            <ButtonBox name="ChangePhoneNumber" ButtonName="変更" @click="changePhoneNumber()"></ButtonBox>
          </td>
        </tr>
        -->
        <tr>
          <td>
            <p>メールアドレス:</p>
          </td>
          <td>
            <p class="showParameters">{{ MailAddress }}</p>
          </td>
          <td>
            <ButtonBox name="ChangeMailAddress" ButtonName="変更" @click="changeMailAddress()"></ButtonBox>
          </td>
        </tr>
        <tr>
          <td>
            <p>パスワード:</p>
          </td>
          <td>
            <p class="showParameters">**********************</p>
          </td>
          <td>
            <ButtonBox name="ChangePassword" ButtonName="変更" @click="changePassword()"></ButtonBox>
          </td>
        </tr>
      </table>
      <hr>
      <table>
        <tr>
          <td class="returnBefore">
            <ButtonBox name="returnBefore" ButtonName="戻る" @click="returnBefore()"></ButtonBox>
          </td>
          <td class="ApplyProfile">
            <ButtonBox v-if="!Admin" class="deleteOwn" name="deleteOwn" ButtonName="削除" @click="deleteOwn()"></ButtonBox>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import Common from '../modules/common.js'
import commonCognito from '../modules/commonCognito.js'

export default {
  name: 'EditProfile',
  components: {
    LabelBox,
    ButtonBox,
    ModalBox,
    MenuPanel
  },
  data () {
    return {
      UserID: sessionStorage.getItem('userid'),
      UserName: sessionStorage.getItem('username'),
      PhoneNumber: sessionStorage.getItem('phonenumber'),
      MailAddress: sessionStorage.getItem('mailaddress'),
      isVisibleConfirm1: false,
      isVisibleConfirm2: false,
      isVisibleConfirm3: false,
      Admin: Common.isAdminUser()
    }
  },
  mounted() {
    sessionStorage.setItem('BeforeMailAddress', location.pathname)
    sessionStorage.setItem('BeforePassword', location.pathname)
    //await sessionStorage.setItem('BeforePhoneNumber', location.pathname)
    sessionStorage.setItem('BeforeUserName', location.pathname)
  },
  methods: {
    closeConfirmDialog1 (e) {
      this.isVisibleConfirm1 = false
      if (e === '1') {
        return
      }
      this.isVisibleConfirm2 = true
    },
    closeConfirmDialog2 (e) {
      this.isVisibleConfirm2 = false
      if (e === '0') {
        return
      }
      this.isVisibleConfirm3 = true
    },
    async closeConfirmDialog3 (e) {
      this.isVisibleConfirm3 = false
      if (e === '1') {
        return
      }
      // 3回の確認を終えた後、初めて削除処理画面へ移行する。
      // サインアウト状態にする
      commonCognito.CognitoSignout()
      // ログインIDをストア
      sessionStorage.setItem('DeleteUserID', this.UserID)
      this.$router.replace('UserDelete')      
    },
    changeMailAddress () {
      // セッションチェック
      if (!commonCognito.checkSession(this)) return

      this.$router.replace('SetMailAddress')
    },
    changePassword () {
      // セッションチェック
      if (!commonCognito.checkSession(this)) return

      this.$router.replace('SetPassword')
    },
    changeUserName () {
      // セッションチェック
      if (!commonCognito.checkSession(this)) return

      this.$router.replace('SetUserName')
    },
    returnBefore () {
      // セッションチェック
      if (!commonCognito.checkSession(this)) return
      const beforePage = sessionStorage.getItem('setBeforeEditProfile')
      sessionStorage.removeItem('setBeforeEditProfile')
      sessionStorage.removeItem('BeforeMailAddress')
      sessionStorage.removeItem('BeforePassword')
      sessionStorage.removeItem('BeforePhoneNumber')
      sessionStorage.removeItem('BeforeUserName')
      sessionStorage.removeItem('PhoneNumberisChange')
      sessionStorage.removeItem('PasswordChange')
      this.$router.replace(beforePage)
    },
    deleteOwn () {
      // セッションチェック
      if (!commonCognito.checkSession(this)) return

      this.isVisibleConfirm1 = true
      // this.$router.replace('UserDelete')
    }
  },
  computed: {

  }
}
</script>

<style scoped>
  table {
    margin: 0px;
    color:#000;
    width: 100%;
    border-collapse: collapse;
  }

  td,tr {
    text-align: left;
    margin: 0px;
    padding: 0px;
    line-height: 0.5;
  }

  .returnBefore {
    text-align: left;
    width: 50%;
  }

  .ApplyProfile {
    text-align: right;
    width: 50%;
  }

  .deleteOwn {
    background-color: red;
    color: rgb(255, 255, 255);
  }

  .deleteOwn:hover {
    background-color: rgb(63, 0, 0);
    color: rgb(255, 249, 172);
  }
  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 800px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }

  .showParameters {
    border-bottom: 1px solid #000000;
    padding-bottom: 7px;
    width: 550px;
    word-wrap: break-word; /* 単語の途中でも改行する */
    overflow-wrap: break-word; /* 古いブラウザに対する互換性のために追加 */
    white-space: normal;
    line-height: 1;
  }
</style>
