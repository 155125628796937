<template>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="認証コードの確認中です。"
    title="認証コード検証"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleParamError"
    message="認証コードは必ず入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="CloseParamError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleErrorMessage"
    :message="ErrorMessage"
    :title="ErrorTitle"
    btn1Text="OK"
    messageType="2"
    @close="CloseErrorMessage"
  ></ModalBox>
  <MenuPanel id="MenuPanel" showLogout="false" PageName="認証コード確認" showUserEdit="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <p>認証コード: <LabelBox id="verificationCode" TypeName="text" name="password" oncontextmenu="return false" maxlength="10"></LabelBox></p>
      <div class="ButtonLayout">
        <ButtonBox name="ReturnButton" ButtonName="戻る" @click="ReturnLogin()"></ButtonBox>
        <ButtonBox name="LoginButton" ButtonName="送信" @click="SendCode()"></ButtonBox>
      </div>
      <p class="information">認証コードが届かない場合、最初からやり直してください。</p>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'
import CommonCognito from '../modules/commonCognito.js'

export default {
  name: 'AuthFromMail',
  components: {
    LabelBox,
    ButtonBox,
    MenuPanel,
    ModalBox,
    ProgressBox
  },
  data () {
    return {
      isVisibleProgress1: false,
      isVisibleErrorMessage: false,
      isVisibleParamError: false,
      ErrorMessage: '',
      ErrorTitle: ''
    }
  },
  methods: {
    CloseParamError () {
      this.isVisibleParamError = false
    },
    CloseErrorMessage (e) {
      const retScene = sessionStorage.getItem('BeforeMailAddress')
      this.isVisibleErrorMessage = false
      this.$router.replace(retScene)
    },
    async SendCode () {
      // 認証コード送信を実行。AWS Cognitoの認証処理を実施する。
      // 認証OKの場合、次の画面へ遷移(一般ユーザー/管理者によって画面遷移が異なる予定)
      const userID = sessionStorage.getItem('userid')
      const status = sessionStorage.getItem('status')

      // NGの場合、認証コードエラーとして、ログイン画面に戻る。
      const verificationCode = document.getElementById('verificationCode').value
      if (verificationCode === '') {
        this.isVisibleParamError = true
        return 
      }

      //const PasswordChange = sessionStorage.getItem('PasswordChange')
      const MailAddressChange = sessionStorage.getItem('MailAddressChange')
      let result
      let message = ""

      this.isVisibleProgress1 = true
      if (!Common.VulnerabilityAssessment) {
        [result, message] = await CommonCognito.verifyEmailCode(verificationCode) 
      } else {
        result = 0
      }
      // 認証エラーとなった場合、エラーメッセージを表示し以降の処理を実施しない
      if (result !== 0) {
        switch (result) {
          case 1:
            this.ErrorTitle = '認証コードエラー'
            this.ErrorMessage = '認証コードに誤りがあります。<br>' +
                                'もう一度メールアドレスの変更画面からやり直してください'
            break
          case 2:
            this.ErrorTitle = '有効期限切れ'
            this.ErrorMessage = '認証コードの有効期限が切れています。<br>' +
                                'もう一度メールアドレスの変更画面からやり直してください'
            break
          default:
            this.ErrorTitle = '予期しないエラー'
            this.ErrorMessage = '認証コードの確認に失敗しました。<br>' + 
                                'しばらく待ってからもう一度「メールアドレスの変更画面」からやり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:'+ message
            break
        }
        this.isVisibleProgress1 = false
        this.isVisibleErrorMessage = true
        return
      }

      const mailaddress = await sessionStorage.getItem('updateMailAddress')

      // 申請処理開始
      const resultDB = await this.ChangeMailAddress() 
      this.isVisibleProgress1 = false

      // DB更新失敗した場合、Cognitoのメールアドレスを元の状態に戻す。
      // それ自体もNGとなった場合は、管理者介入
      // 課題：ログに出力し、状況を分かるようにする
      if (!resultDB) {
        /*
        this.ErrorTitle = '更新エラー'
        this.ErrorMessage = 'メールアドレスの更新に失敗しました。<br>最初からやり直してください。'
        this.isVisibleErrorMessage = true
        */
        return
      }
      
      // いずれにしても画面は戻るので、クリアしておく
      await sessionStorage.removeItem('presentMailAddress')
      await sessionStorage.removeItem('updateMailAddress')

      const retScene = sessionStorage.getItem('BeforeMailAddress')

      await sessionStorage.setItem('mailaddress', mailaddress) // 更新が成功したならSession側も修正する
      this.$router.replace(retScene)
    },
    async ReturnLogin () {
      const retScene = sessionStorage.getItem('BeforeMailAddress')

      await sessionStorage.removeItem('MailAddressChange')
      await sessionStorage.removeItem('presentMailAddress')
      await sessionStorage.removeItem('updateMailAddress')
      
      this.$router.replace(retScene)
    },
    async ChangeMailAddress() {
      // Lambdaをたたいて処理する
      const userID = sessionStorage.getItem('userid')
      const beforemailaddress = await sessionStorage.getItem('presentMailAddress')
      const mailaddress = await sessionStorage.getItem('updateMailAddress')

      let admin = '0'
      let url = URLS.authFromMail_updateMailAddress[Common.RELEASE]
      if (Common.isAdminUser()) {
        admin = '1'
        url = URLS.authFromMail_updateMailAddress_admin[Common.RELEASE]
      }
      // API Gatewayに渡すクエリパラメータ設定
      //const url = 'https://20en3sy9fd.execute-api.ap-northeast-1.amazonaws.com/alpha2/'

      const requestBody = {
        userid: userID,
        mailaddress: mailaddress,
        beforemailaddress: beforemailaddress,
        admin: admin
      }
      // POSTになった場合、Responseを.jsonしないと、中身が見れない。そしてstatusCodeから確認しないとダメ。
      const responseAll = await Common.POSTRequest(url, requestBody)
      const [status, body] = await Common.POSTResponse(responseAll, this)

      Common.logPrint(status)
      if (status === 200) {
        return true
      } else {
        // const errCode = await Common.getErrorCodeFromResponceBody(responseAll.body)
        const errCode = await Common.getErrorCodeFromResponceBody(body)
        Common.logPrint(errCode)
        switch(errCode) {
          case 500:
            this.ErrorTitle = 'DB接続エラー'
            this.ErrorMessage = '認証コードの確認に失敗しました。<br>' +
                                'しばらく待ってからもう一度やり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            break
          case 501:
            this.ErrorTitle = 'Cognito更新エラー'
            this.ErrorMessage = '認証コードの確認に失敗しました。<br>' +
                                'しばらく待ってからもう一度やり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            break
          case 502:
            this.ErrorTitle = 'メール送信エラー'
            this.ErrorMessage = 'メールの送信に失敗しました。<br>' +
                                'メールアドレスをご確認の上、もう一度やり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            break
          case 510:
            this.ErrorTitle = 'サーバーエラー'
            this.ErrorMessage = '処理中にタイムアウトが発生しました。<br>' +
                                'しばらく待ってからもう一度やり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。'
            break
          default:
            this.ErrorTitle = '予期しないエラー'
            this.ErrorMessage = '認証コードの確認に失敗しました。<br>' +
                                'しばらく待ってからもう一度やり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            break
        }
        this.isVisibleErrorMessage = true
        return false
      }
    }
  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  p {
    color:black;
  }

  .information {
    font-size: 11px;
  }

  .ButtonLayout {
    text-align: center;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 350px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    color: #fff;
    text-align: center;
  }

 </style>
