<template>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度やり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputError1"
    message="全ての項目を入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputError1"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="ユーザー名の変更中です。"
    title="変更中"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleInputError2"
    message="入力したユーザー名が現在のユーザー名と同一です。<br>新しいユーザー名を入力してください。<br><br>変更しない場合は、戻るボタンを押してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputError2"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleErrorMessage"
    :message="ErrorMessage"
    title="更新エラー"
    btn1Text="OK"
    messageType="2"
    @close="CloseErrorMessage"
  ></ModalBox>
  <MenuPanel PageName="ユーザー名変更" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
    <table>
        <tr>
          <td>
            <p class="required">ユーザー名:</p>
          </td>
          <td>
            <LabelBox :value="showUserName" id="UserName" TypeName="text" name="UserName" oncontextmenu="return false" maxlength="50"></LabelBox>
          </td>
        </tr>
      </table>
      <hr>
      <div class="Apply">
        <ButtonBox name="returnBefore" ButtonName="戻る" @click="returnBefore()"></ButtonBox>
        <ButtonBox name="Apply" ButtonName="変更" @click="Apply()"></ButtonBox>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import URLS from '../modules/url.js'
import commonCognito from '../modules/commonCognito.js'

export default {
  name: 'SetUserName',
  data () {
    return {
      isVisibleInputError1: false,
      isVisibleInputUserName: false,
      isVisibleProgress1: false,
      isVisibleErrorMessage: false,
      isVisibleInputError2: false,
      isVisibleTimeout: false,
      ErrorMessage: '',
      showUserName: sessionStorage.getItem('username')
    }
  },
  components: {
    LabelBox,
    ButtonBox,
    MenuPanel,
    ModalBox,
    ProgressBox
  },
  mounted() {
    //document.getElementById('')
  },
  methods: {
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
    },
    closeDialogInputUserName (e) {
      this.isVisibleInputUserName = false
    },
    closeDialogInputError1 (e) {
      this.isVisibleInputError1 = false
    },
    closeDialogInputError2 (e) {
      this.isVisibleInputError2 = false
    },
    CloseErrorMessage (e) {
      this.isVisibleErrorMessage = false
    },
    async ChangeUserName(username) {
      // Lambdaをたたいて処理する
      const userID = sessionStorage.getItem('userid')

      let admin = '0'
      if (Common.isAdminUser()) {
        admin = '1'
      }
      // API Gatewayに渡すクエリパラメータ設定
      //const url = 'https://gfduatzwvh.execute-api.ap-northeast-1.amazonaws.com/alpha2/'
      let url
      if (!Common.isAdminUser()) {
        url = URLS.setUserName_updateUserName[Common.RELEASE]
      } else {
        url = URLS.setUserName_updateUserName_admin[Common.RELEASE]
      }
      const requestBody = {
        userid: userID,
        username: username,
        admin: admin
      }
      const responseAll = await Common.POSTRequest(url, requestBody)
      const [status, body] = await Common.POSTResponse(responseAll, this)

      if (status !== 200) {
        const errCode = await Common.getErrorCodeFromResponceBody(body)
        switch (errCode) {
          case 510:
            this.isVisibleTimeout = true
            break

          case 501:
            this.ErrorMessage = 'ユーザー名の変更エラーが発生しました。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            this.isVisibleErrorMessage = true
            break
          
          default:
            this.ErrorMessage = "ユーザー名の変更に失敗しました。<br>" +
                                "しばらく待ってからもう一度やり直してください。<br><br>" +
                                "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                                "Message:" + body
            this.isVisibleErrorMessage = true            
        }
        return false
      } else {
        return true
      }
    },
    async returnBefore () {
      // セッションチェック
      if(!commonCognito.checkSession(this)) return
      const beforePage = await sessionStorage.getItem('BeforeUserName')
      this.$router.replace(beforePage)
    },
    async Apply () {
      // セッションチェック
      if(!commonCognito.checkSession(this)) return
      const username = document.getElementById('UserName').value
      const beforeusername = sessionStorage.getItem('username')

      // const userid = sessionStorage.getItem("userid")
      // 全ての情報が登録されていない場合はエラー
      if (username === '') {
        this.isVisibleInputError1 = true
        return
      }

      // ユーザー名に変更がない場合エラー
      if (username === beforeusername) {
        this.isVisibleInputError2 = true
        return
      }
      // 変更処理を実行する
      this.isVisibleProgress1 = true
      const result = await this.ChangeUserName(username) 
      this.isVisibleProgress1 = false
      
      if (result) {
        const retScene = sessionStorage.getItem('BeforeUserName')
        await sessionStorage.setItem('username', username) // 更新が成功したならSession側も修正する
        this.$router.replace(retScene)
      } 
    }
  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  .attention {
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 15px;
    color:#ff3838;
    line-height: 1.2;
  }

  td,tr {
    text-align: left;
    margin: 0px;
    padding: 0px;
    line-height: 0.5;
  }

  .Apply {
    text-align: right;
    margin-right: 30px;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 600px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }

  .passwordReset {
    font-size: 12px;
  }

  .required::after {
    background-color: red;
    color: white;
    text-align: left;
    content:"\5fc5\9808";
    border-radius:3px;
    font-size: 8px;
    padding: 3px;
    margin: 2px;
    vertical-align: middle;
  }
</style>
