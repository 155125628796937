<template>
  <ProgressBox
    :isVisible="isVisibleResetProgress"
    message="パスワードリセットを行っています。<br>少々お待ちください。"
    title="リセット申請中"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleErrorReset"
    :message="errorMessage"
    title="パスワードリセット処理エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeCompletedError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleCompleted"
    message="登録したメールアドレスに、認証コードを送信しました。<br>次の画面で、認証コードを入力してください。"
    title="認証コード送信完了"
    btn1Text="OK"
    messageType="0"
    @close="closeCompleted"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleParamError"
    message="サインインIDは必ず入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeParamError"
  ></ModalBox>
  <MenuPanel PageName="パスワードリセット申請" showLogout="false" showUserEdit="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <table>
        <tr>
          <td>サインインID:</td>
          <td><LabelBox id="userID" TypeName="username" name="userID" maxlength="20"></LabelBox></td>
        </tr>
      </table>
      <ButtonBox name="SendCode" ButtonName="戻る" @click="returnBefore()"></ButtonBox>
      <ButtonBox name="SendCode" ButtonName="申請" @click="resetpassword()"></ButtonBox>
    </div>
  </div>
</template>

<script>
import ModalBox from '../components/ModalBox.vue'
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import commonCognito from '../modules/commonCognito.js'

export default {
  name: 'PasswordReset',
  data () {
    return {
      isVisibleResetProgress: false,
      isVisibleErrorReset: false,
      isVisibleCompleted: false,
      isVisibleParamError: false,
      errorMessage: ''
    }
  },
  components: {
    LabelBox,
    ButtonBox,
    ModalBox,
    ProgressBox,
    MenuPanel
  },
  methods: {
    closeParamError () {
      this.isVisibleParamError = false
    },
    closeCompletedError () {
      this.isVisibleErrorReset = false
      this.$router.replace('/')
    },
    closeCompleted () {
      this.$router.replace('/ResetAuth')
    },
    async resetpassword () {
      this.isVisibleResetProgress = true
      //
      const userid = document.getElementById('userID').value
      if (userid === '') {
        this.isVisibleResetProgress = false
        this.isVisibleParamError = true
        return
      }
      
      // Cognitoからパスワードリセット用の認証コードが送信される。
      const [result,e] = await commonCognito.forgotPassword(userid)
      // Resetエラー発生の場合
      if (!result) {
        this.isVisibleResetProgress = false
        this.errorMessage = "パスワードリセット処理でエラーが発生しました。<br>" +
                            "サインイン画面に戻ります<br>" + 
                            "Message:" + e
        this.isVisibleErrorReset = true
        return
      }

      this.isVisibleResetProgress = false
      this.isVisibleCompleted = true
    },
    returnBefore () {
      this.$router.replace('/')
      // history.back()
    }
  }
}
</script>

<style scoped>
  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  td {
    margin: 10px;
    text-align: left;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 350px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 0px;
    border-color: rgb(230, 230, 230);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }
</style>
