import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

// Global変数定義
// 使用する場合、inject: ['GlobalDatas']
// this.GlobalDatas.要素名で

app.use(router)
app.mount('#app')
