<template>
  <select v-model="selectedItem" :id="name" :name="name">
    <option v-for="data in showItems" :value="data.id" :key="data.id">{{ data.label }}</option>
  </select>
</template>

<script>
import Common from '../modules/common.js'
const componentID = 'CP007'

export default {
  name: 'SelectBox',
  props: {
    name: String,
    Datas: Array,
    width: {
      type: String,
      default: '100px'
    },
    initIndex: {
      type: Number,
      default: -1
    },
    initData: {
      type: String,
      default: ''
    }
  },
  mounted () {
    // console.log(this.initData)
    if (this.initIndex === -1) return
    if (this.initData === '') {
      document.getElementById(this.name).selectedIndex = this.initIndex
    } else {
      document.getElementById(this.name).selectedItem = this.initData
    }
  },
  data () {
    return {
      selectedItem: ''
    }
  },
  methods: {
    getSelectedIndex: function () {
      document.getElementById('sendType').selectedIndex
    },
    getSelectedItem: function () {

    }
  },
  computed: {
    showItems: function () {
      const retArray = []
      this.Datas.forEach((element, index) => {
        retArray.push(
          {
            label: element,
            id: index
          }
        )
      })
      return retArray
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  padding: 2px 7px;
  border: 2px solid rgb(71, 71, 71);
}

select:focus {
  padding: 3px 7px;
  background-color: rgb(183, 228, 255);
  box-shadow: 0px 0px 5px 0px #7b6dff;
  border-radius: 4px;
}
</style>
