<template>
  <ModalBox
    :isVisible="isVisibleInputSameMail"
    message="新しいメールアドレスは現在のメールアドレスと異なる値に設定してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputSameMail"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputMailCheck"
    message="正しい形式でメールアドレスを入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputMailCheck"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputError1"
    message="全ての項目を入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputError1"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputMailAddress"
    message="新メールアドレスと新メールアドレス(確認用)が一致していません。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogInputMailAddress"
  ></ModalBox>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="認証コードの送信中です。"
    title="認証コード送信中"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleErrorMessage"
    :message="ErrorMessage"
    title="更新エラー"
    btn1Text="OK"
    messageType="2"
    @close="CloseErrorMessage"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleCompleted"
    message="入力された新メールアドレスに、認証コードを送信しました。<br>次の画面で、認証コードを入力してください。"
    title="認証コード送信完了"
    btn1Text="OK"
    messageType="0"
    @close="closeCompleted"
  ></ModalBox>
  <MenuPanel PageName="メールアドレスの変更" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <form>
        <table>
          <tr>
            <td>
              <p>旧メールアドレス:</p>
            </td>
            <td>
              <p class="showParameters">{{ nowMail }}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="required">新メールアドレス:</p>
            </td>
            <td>
              <LabelBox id="mailAddress" TypeName="email" class="InputMailAddress" oncontextmenu="return false" maxlength="320"></LabelBox>
            </td>
          </tr>
          <tr>
            <td>
              <p class="required">新メールアドレス(確認用):</p>
            </td>
            <td>
              <LabelBox id="reMailAddress" TypeName="email" class="InputMailAddress" oncontextmenu="return false" maxlength="320"></LabelBox>
            </td>
          </tr>
        </table>
        <hr>
        <div class="Apply">
          <ButtonBox name="returnBefore" ButtonName="戻る" @click="returnBefore()"></ButtonBox>
          <ButtonBox name="Apply" ButtonName="変更" @click="Apply()"></ButtonBox>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import commonCognito from '../modules/commonCognito.js'

export default {
  name: 'SetMailAddress',
  data () {
    return {
      isVisibleInputError1: false,
      isVisibleInputMailAddress: false,
      isVisibleProgress1: false,
      isVisibleErrorMessage: false,
      isVisibleInputMailCheck: false,
      isVisibleCompleted: false,
      ErrorMessage: '',
      nowMail: sessionStorage.getItem('mailaddress'),
      isVisibleInputSameMail: false
    }
  },
  components: {
    LabelBox,
    ButtonBox,
    MenuPanel,
    ModalBox,
    ProgressBox
  },
  methods: {
    closeCompleted () {
      this.isVisibleCompleted = false
      this.$router.replace('AuthFromMail')
    },
    closeDialogInputSameMail () {
      this.isVisibleInputSameMail = false
    },
    closeDialogInputMailAddress (e) {
      this.isVisibleInputMailAddress = false
    },
    closeDialogInputError1 (e) {
      this.isVisibleInputError1 = false
    },
    CloseErrorMessage (e) {
      this.isVisibleErrorMessage = false
    },
    closeDialogInputMailCheck (e) {
      this.isVisibleInputMailCheck = false
    },
    async returnBefore () {
      // セッションチェック
      if(!commonCognito.checkSession(this)) return
      const beforePage = await sessionStorage.getItem('BeforeMailAddress')
      this.$router.replace(beforePage)
    },
    async Apply () {
      // セッションチェック
      Common.logPrint("セッションチェック前")
      if(!commonCognito.checkSession(this)) return

      Common.logPrint("セッションチェック後")
      const mail1 = document.getElementById('mailAddress').value
      const mail2 = document.getElementById('reMailAddress').value
      const beforemailaddress = await sessionStorage.getItem('mailaddress')

      // const userid = sessionStorage.getItem("userid")
      // 全ての情報が登録されていない場合はエラー
      if (mail1 === '' ||
          mail2 === '') {
        this.isVisibleInputError1 = true
        return
      }

      // 2つの入力情報が一致していない場合はエラー
      if (mail1 !== mail2) {
          this.isVisibleInputMailAddress = true
          return
      }

      // 同一メールアドレス
      if (mail1 === beforemailaddress) {
        this.isVisibleInputSameMail = true
        return
      }
      
      // メールアドレスの正しさ確認
      if (!Common.checkMailAddress(mail1)) {
        this.isVisibleInputMailCheck = true
        return
      }
      this.isVisibleProgress1 = true
      // 新メールアドレスに対し、CognitoからMFA認証コードを発行する
      const beforeScene = sessionStorage.getItem('BeforeMailAddress')

      // パラメーターを受け渡す
      await sessionStorage.setItem('AuthBefore', beforeScene)
      await sessionStorage.setItem('presentMailAddress', sessionStorage.getItem('mailaddress'))
      await sessionStorage.setItem('updateMailAddress', mail1)
      await sessionStorage.setItem('MailAddressChange', true) 

      // 変更を完了させるためには認証コードによる認証が必要(6/16)
      //
      const [result, e] = await commonCognito.changeEmailAttributes(mail1)
      //
      //const result = true
      // Cognitoでエラーが起きた場合
      if (!result) {
        this.ErrorMessage = '認証コードの送信に失敗しました。<br>' +
                            'しばらく待ってから、もう一度やり直してください。<br>' +
                            'Message: ' + e
        this.isVisibleProgress1 = false
        this.isVisibleErrorMessage = true
        return
      } 
      this.isVisibleProgress1 = false
      this.isVisibleCompleted = true
    }
  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  .attention {
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 15px;
    color:#ff3838;
    line-height: 1.2;
  }

  td,tr {
    text-align: left;
    margin: 0px;
    padding: 0px;
    line-height: 0.5;
  }

  .InputMailAddress {
    width:700px;
  }

  .Apply {
    text-align: right;
    margin-right: 30px;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 950px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }

  .passwordReset {
    font-size: 12px;
  }
  .showParameters {
    border-bottom: 1px solid #000000;
    padding-bottom: 8px;
    word-wrap: break-word; /* 単語の途中でも改行する */
    overflow-wrap: break-word; /* 古いブラウザに対する互換性のために追加 */
    white-space: normal;
    line-height: 1;
    width: 720px;
  }

  .required::after {
    background-color: red;
    color: white;
    text-align: left;
    content:"\5fc5\9808";
    border-radius:3px;
    font-size: 8px;
    padding: 3px;
    margin: 2px;
    vertical-align: middle;
  }
</style>
