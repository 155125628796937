<template>
  <MenuPanel PageName="不正なアクセス" showUserEdit="false" showLogout="true"></MenuPanel>
  <div class="page">
    <div class="home">
      <h1>不正なアクセスが行われました。</h1>
      <p>右上のサインアウトボタンを押下して最初の画面に戻ってください。</p>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import MenuPanel from '../components/MenuPanel.vue'

export default {
  name: 'AccessDenied',
  components: {
    MenuPanel
  },
  methods: {
  }
}
</script>

<style scoped>
  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 800px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    text-align: center;
  }
</style>
