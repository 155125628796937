<template>
  <ProgressBox
    :isVisible="isVisibleProgress1"
    message="認証コードの確認中です。"
    title="認証コード検証中"
  ></ProgressBox>
  <ModalBox
    :isVisible="isVisibleParamError"
    message="認証コードは必ず入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="CloseParamError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleErrorMessage"
    :message="ErrorMessage"
    :title="ErrorTitle"
    btn1Text="OK"
    messageType="2"
    @close="CloseErrorMessage"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleTimeout"
    message="処理中にタイムアウトが発生しました。<br>しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>問題が解決しない場合は、問い合わせ窓口へお問い合わせください。"
    title="サーバーエラー"
    btn1Text="OK"
    messageType="2"
    @close="closeDialogTimeoutDitect"
  ></ModalBox>
  <MenuPanel :HomeJump="false" id="MenuPanel" showLogout="false" PageName="認証コード確認" showUserEdit="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <p>認証コード: <LabelBox id="verificationCode" TypeName="text" name="password" oncontextmenu="return false" maxlength="10"></LabelBox></p>
      <div class="ButtonLayout">
        <ButtonBox name="ReturnButton" ButtonName="戻る" @click="ReturnLogin()"></ButtonBox>
        <ButtonBox name="LoginButton" ButtonName="送信" @click="SendCode()"></ButtonBox>
      </div>
      <p class="information">認証コードが届かない場合、最初からやり直してください。</p>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import ProgressBox from '../components/ProgressBox.vue'
import Common from '../modules/common.js'
import CommonCognito from '../modules/commonCognito.js'
import URLS from '../modules/url.js'
export default {
  name: 'AuthView',
  components: {
    LabelBox,
    ButtonBox,
    MenuPanel,
    ModalBox,
    ProgressBox
  },
  data () {
    return {
      isVisibleProgress1: false,
      isVisibleErrorMessage: false,
      isVisibleParamError: false,
      isVisibleTimeout: false,
      ErrorMessage: '',
      ErrorTitle: ''
    }
  },
  methods: {
    closeDialogTimeoutDitect () {
      this.isVisibleTimeout = false
      this.ReturnLogin()
    },
    CloseParamError () {
      this.isVisibleParamError = false
    },
    CloseErrorMessage (e) {
      this.isVisibleErrorMessage = false
      this.ReturnLogin()
    },
    async verifyMFA (verificationCode) {
      //6/16用
      const url = URLS.auth_MFAVerification[Common.RELEASE]

      const mfaSession = sessionStorage.getItem('mfaSession')
      const userID = sessionStorage.getItem('mfaUserId')
      const requestBody = {
        userID: userID,
        answer: verificationCode,
        session: mfaSession
      }

      const response = await Common.POSTRequestWithoutAuth(url, requestBody)
      const [status, body] = await Common.POSTResponse(response, this)

      // サインイン後ステータスを確認し、処理を行う。
      if (status === 200) {
        sessionStorage.setItem('userAccessToken', body.AccessToken)
        sessionStorage.setItem('userIdToken', body.IdToken)
        sessionStorage.setItem('userRefreshToken', body.RefreshToken)
        sessionStorage.removeItem('mfaSession')
        sessionStorage.removeItem('mfaUserId')
        return true
      } else {
        this.isVisibleProgress1 = false
        //const errCode = await Common.getErrorCodeFromResponceBody(body)
        switch(status) {
          // 認証コード誤り
          case 501:
            this.ErrorTitle = '認証コードエラー'
            this.ErrorMessage = '認証コードに誤りがあります。<br>' +
                                'もう一度サインイン画面からやり直してください'
            this.isVisibleErrorMessage = true
            break

          // 認証コード有効期限切れ
          case 502:
            this.ErrorTitle = '有効期限切れ'
            this.ErrorMessage = '認証コードの有効期限が切れています。<br>' +
                                'もう一度サインイン画面からやり直してください'
            this.isVisibleErrorMessage = true
            break

            // Time Out
          case 510:
            Common.logPrint("タイムアウト検知")
            this.isVisibleTimeout = true
            break
                
          default:
            this.ErrorTitle = '予期しないエラー'
            this.ErrorMessage = '認証コードの確認に失敗しました。<br>' + 
                                'しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>' +
                                '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                'Message:' + body
            this.isVisibleErrorMessage = true
            break
        }
        sessionStorage.removeItem('mfaSession')
        sessionStorage.removeItem('userAccessToken')
        sessionStorage.removeItem('userIdToken')
        sessionStorage.removeItem('userRefreshToken')
        sessionStorage.removeItem('mfaUserId')
        return false
      }
    },
    async SendCode () {
      // 認証コード送信を実行。AWS Cognitoの認証処理を実施する。
      // 認証OKの場合、次の画面へ遷移(一般ユーザー/管理者によって画面遷移が異なる予定)
      const userID = sessionStorage.getItem('userid')

      // 認証コード未入力確認
      const verificationCode = document.getElementById('verificationCode').value
      if (verificationCode === '') {
        this.isVisibleParamError = true
        return
      }

      // 入力された認証コードをCognitoに問い合わせ
      // NGの場合、認証コードエラーとして、ログイン画面に戻る。
      // 認証コード検証実施
      this.isVisibleProgress1 = true
      let result
      Common.logPrint(Common.VulnerabilityAssessment)
      if (!Common.VulnerabilityAssessment) {
        result = await this.verifyMFA(verificationCode) 
      } else {
        result = true
      }
      
      // 認証エラーとなった場合、エラーメッセージを表示し以降の処理を実施しない
      if (!result) {
        return
      }

      // ユーザーの情報を取得する処理を実施する
      const [statusCode, body] = await this.getPermissonsFromRDS(userID)
      if (statusCode === 200) {
        const response = body
        const username = response.UserName
        const userClient = response.ClientCode
        const mailAddress = response.MailAddress
        const status = response.Status
        sessionStorage.setItem('userid', userID)
        sessionStorage.setItem('username', username)
        sessionStorage.setItem('userClient', userClient)
        sessionStorage.setItem('mailaddress', mailAddress)
        //sessionStorage.setItem('status', status)
      } else {
        this.isVisibleProgress1 = false
        const errCode = await Common.getErrorCodeFromResponceBody(body)
        Common.logPrint(errCode)
        switch(errCode) {
          // Time Out
          case 510:
            Common.logPrint("タイムアウト検知")
            this.isVisibleTimeout = true
            break
                
          default:
            this.ServerErrorMessage = 'ユーザー情報の取得に失敗しました。<br>' +
                                      'しばらく待ってからもう一度「サインイン画面」からやり直してください。<br><br>' +
                                      '問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>' +
                                      'Message:' + body
            this.isVisibleServerError = true
            break
        }
      }

      sessionStorage.removeItem('AuthBefore')
      const resultUpdate = await this.updateSignIn(userID) // ←Lambdaの変更要かも。
      this.isVisibleProgress1 = false
      
      // Lambda タイムアウト発生
      if (resultUpdate === 510) {
        this.isVisibleTimeout = true
        return
      }
      this.$router.replace({ name: 'ReportList' })
    },
    async ReturnLogin () {
      const beforeScreen = sessionStorage.getItem('AuthBefore')
      sessionStorage.removeItem('AuthBefore')
      
      this.$router.replace(beforeScreen)
    },
    async updateSignIn (iuserID) {
      //const url = 'https://gi20afxye1.execute-api.ap-northeast-1.amazonaws.com/alpha2'
      const url = URLS.auth_updateLastSignIn[Common.RELEASE]
      const requestBody = {
        userid: iuserID,
        admin: '0'
      }
      const response = await Common.POSTRequest(url, requestBody)
      return response.status
    },
    async getPermissonsFromRDS (iuserID) {
      const url = URLS.userLogin_getUserInformation[Common.RELEASE] 
      const requestBody = {
        userid: iuserID,
        admin: '0'
      }

      const response = await Common.POSTRequest(url, requestBody)
      const ret = await Common.POSTResponse(response, this)
      return ret
    }
  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  p {
    color:black;
  }

  .information {
    font-size: 11px;
  }

  .ButtonLayout {
    text-align: center;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 350px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    color: #fff;
    text-align: center;
  }

 </style>
