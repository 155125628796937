<template>
  <MenuPanel PageName="セッションタイムアウト" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <b>長時間操作が無かったため、自動的にサインアウトしました。</b><br>
      サインインからやり直してください。
      <div class="returnSignIn">
        <ButtonBox name="returnSignIn" ButtonName="サインイン画面に戻る" @click="returnSignIn()"></ButtonBox>
      </div>
    </div>
  </div>

</template>

<script>

// @ is an alias to /src
import MenuPanel from '../components/MenuPanel.vue'
import ButtonBox from '../components/Button.vue'

export default {
  name: 'AccessDenied',
  components: {
    MenuPanel,
    ButtonBox
  },
  methods: {
    async returnSignIn () {
      sessionStorage.clear()
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>
  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 800px;
    text-align: center;
  }

  .redSign {
    color: red;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    text-align: center;

  }

  .returnSignIn {
    text-align: center;
  }
</style>
