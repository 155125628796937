<template>
  <ModalBox
    :isVisible="isVisibliePolicyError"
    message="入力したパスワードが、パスワードポリシーを満たしていません。<br>最低10文字で大文字、小文字、数字、および特殊文字を含めてください。"
    title="ポリシー違反"
    btn1Text="OK"
    messageType="1"
    @close="closeDialogPolicyError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleInputError"
    message="全ての項目を入力してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeInputError"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleCompeted"
    message="パスワードの更新が完了しました。<br>サインイン画面に戻ります。"
    title="更新完了"
    btn1Text="OK"
    messageType="0"
    @close="closeCompleted"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleChangeConfirm"
    :message="confirmMessage"
    title="パスワード更新確認"
    btn1Text="パスワード更新実施"
    btn2Text="サインインに戻る"
    btn1Color="#FF0000"
    btn1TextColor="#FFFFFF"
    messageType="1"
    @close="closeChangeConfirm"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleErrorPassword"
    message="パスワードと確認用パスワードが一致していません。<br>もう一度確認の上、やり直してください。"
    title="入力エラー"
    btn1Text="OK"
    messageType="1"
    @close="closeChangeErrorPassword"
  ></ModalBox>
  <ModalBox
    :isVisible="isVisibleErrorCognito"
    :message="CognitoErrorMessage"
    :title="CognitoErrorMessageTitle"
    btn1Text="OK"
    messageType="1"
    @close="closeChangeErrorCognito"
  ></ModalBox>
  <MenuPanel PageName="新パスワード入力" showUserEdit="false" showLogout="false"></MenuPanel>
  <div class="page">
    <div class="home">
      <table>
        <tr>
          <td>
            <p class="required">サインインID:</p>
          </td>
          <td>
            <LabelBox TypeName="text" :autocomplete="off" id="userid" name="userid" oncontextmenu="return false" maxlength="20"></LabelBox>
          </td>
        </tr>
        <tr>
          <td>
            <p class="required">認証コード:</p>
          </td>
          <td>
            <LabelBox TypeName="text" :autocomplete="off" id="verificationCode" name="verificationCode" oncontextmenu="return false" maxlength="10"></LabelBox>
          </td>
        </tr>
        <tr>
          <td>
            <p class="required">新パスワード:</p>
          </td>
          <td>
            <LabelBox @input="checkPasswordPolicy" :autocomplete="off" TypeName="password" id="password" name="password" oncontextmenu="return false" maxlength="20"></LabelBox>
          </td>
        </tr>
        <tr>
          <td>
            <p class="required">新パスワード(確認用):</p>
          </td>
          <td>
            <LabelBox TypeName="password" :autocomplete="off" id="repassword" name="password" oncontextmenu="return false" maxlength="20"></LabelBox>
          </td>
        </tr>
      </table>
      <CheckPassword ref="PasswordCheck"></CheckPassword>
      <hr>
      <div class="ApplyPassword">
        <ButtonBox name="returnLogin" ButtonName="サインイン画面へ" @click="returnLogin()"></ButtonBox>
        <ButtonBox name="ApplyPassword" ButtonName="パスワード再設定" @click="ApplyPassword()"></ButtonBox>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LabelBox from '../components/LabelBox.vue'
import ButtonBox from '../components/Button.vue'
import MenuPanel from '../components/MenuPanel.vue'
import ModalBox from '../components/ModalBox.vue'
import CheckPassword from '../components/PasswordCheck.vue'
import Common from '../modules/common.js'
import commonCognito from '../modules/commonCognito.js'

export default {
  name: 'ResetAuthView',
  components: {
    LabelBox,
    ButtonBox,
    MenuPanel,
    ModalBox,
    CheckPassword
  },
  data () {
    return {
      isVisibleChangeConfirm: false,
      isVisibleErrorCognito: false,
      isVisibleCompeted: false,
      isVisibleErrorPassword: false,
      isVisibleInputError: false,
      confirmMessage: '',
      isVisibliePolicyError: false,
      CognitoErrorMessage: '',
      CognitoErrorMessageTitle: ''
    }
  },
  methods: {
    closeDialogPolicyError () {
      this.isVisibliePolicyError = false
    },
    checkPasswordPolicy () {
      const password = document.getElementById("password").value
      this.$refs.PasswordCheck.checkPasswordPolicy(password)
    },
    returnLogin () {
      this.$router.replace('/')
    },
    closeInputError () {
      this.isVisibleInputError = false
    },
    closeCompleted () {
      this.$router.replace('/')
    },
    closeChangeErrorCognito () {
      this.isVisibleErrorCognito = false
    },
    closeChangeErrorPassword () {
      this.isVisibleErrorPassword = false
    },
    async closeChangeConfirm (e) {
      this.isVisibleChangeConfirm = false
      // パスワードリセットをキャンセルする場合
      if (e==='1') {
        this.$router.replace('/')
        return
      }

      const userid = document.getElementById('userid').value
      const verificationCode = document.getElementById('verificationCode').value
      const password = document.getElementById('password').value

      // パスワード更新を実施
      const [result, code] = await commonCognito.confirmForgotPassword(userid, password, verificationCode)
      switch (result) {
        case 0:
          this.isVisibleCompeted = true
          break

        // パスワードポリシーに一致していない(前処理ではじかれると思う)
        case 2:
          this.CognitoErrorMessage = "入力したパスワードが、パスワードポリシーを満たしていません。<br>" +
                                     "最低10文字で大文字、小文字、数字、および特殊文字を含めてください。"
          this.CognitoErrorMessageTitle = "ポリシー違反"
          this.isVisibleErrorCognito = true
          break       
      
        // パスワードの長さ足らない(前処理ではじかれると思う)
        case 3:
          this.CognitoErrorMessage = "入力したパスワードが短すぎます。"
          this.CognitoErrorMessageTitle = "ポリシー違反"
          this.isVisibleErrorCognito = true
          break     

        // 認証コードが間違っている
        case 4:
          this.CognitoErrorMessage = "入力した認証コードに誤りがあります。<br>" +
                                     "もう一度確認の上、やり直してください。"
          this.CognitoErrorMessageTitle = "認証コード誤り"
          this.isVisibleErrorCognito = true
          break 

        // 認証コード期限切れ
        case 5:
          this.CognitoErrorMessage = "認証コードの有効期限が切れています。<br>" +
                                     "もう一度パスワードリセット申請画面からやり直してください"
          this.CognitoErrorMessageTitle = "有効期限切れ"
          this.isVisibleErrorCognito = true
          break 

          // それ以外のエラー
        default:
          this.CognitoErrorMessage = "パスワードの変更に失敗しました。<br>" +
                                     "しばらく待ってからもう一度やり直してください。<br><br>" +
                                     "問題が解決しない場合は、問い合わせ窓口へお問い合わせください。<br>" +
                                     "Message:" + msg
          this.CognitoErrorMessageTitle = "予期しないエラー"
          this.isVisibleErrorCognito = true
      }
    },
    ApplyPassword () {
      const userid = document.getElementById('userid').value
      const password = document.getElementById('password').value
      const repassword = document.getElementById('repassword').value
      const verificationCode = document.getElementById('verificationCode').value
      
      // 入力項目が全て入ってない場合
      if (userid === '' ||
          password === '' ||
          repassword === '' ||
          verificationCode === '') {
        this.isVisibleInputError = true
        return
      }

      // パスワードとパスワード確認用で問題あり
      if (password !== repassword) {
        this.isVisibleErrorPassword = true
        return
      }

      // パスワードポリシーチェック
      if (!this.$refs.PasswordCheck.fullCompleted()) {
        this.isVisibliePolicyError = true
        return
      }

      // this.$router.push({ name: 'CompleteApply' })
      this.confirmMessage='サインインID"'+ userid + '"のパスワードを更新しますか？<br><br>' +
                          '更新しない場合は、「サインインに戻る」を押してください。'
      this.isVisibleChangeConfirm = true
    }
  }
}
</script>

<style scoped>
  .titlePanel {
    background-color: rgb(100, 133, 220);
    color:white;
    padding: 10px 0px;
    height: 40px;
    border: solid 2px;
    border-color: rgb(100, 133, 220);
    text-align: center;
    width: 100%;
  }

  table {
    margin: 10px;
    color:#000;
    width:100%;
    border-collapse: collapse;
  }

  td,tr {
    text-align: left;
    margin: 0px;
    padding: 0px;
    line-height: 0.5;
  }

  .ApplyPassword {
    text-align: center;
  }

  .page {
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 600px;
    text-align: center;
  }

  .home {
    margin: 0px;
    width: 100%;
    line-height: 2.5;
    border: solid 2px;
    border-color: rgb(255, 255, 255);
    color: #fff;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }

  .passwordReset {
    font-size: 11px;
    color:#000;
  }
  .required::after {
    background-color: red;
    color: white;
    text-align: left;
    content:"\5fc5\9808";
    border-radius:3px;
    font-size: 8px;
    padding: 3px;
    margin: 2px;
    vertical-align: middle;
  }
</style>
